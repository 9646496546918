import { 
  Montan, 
  Avoris, 
  Brg, 
  Team6,
  Motel24Seven,
  EU,
  Klipp
} from '../assets/index';

const referenceData = {
  references: [
    {
      "name" : "Montan-Universität Leoben",
      "services": [
        {
          "description" : "Klimatisierung Serverraum 2006, 2008, 2009"
        },
        {
          "description" : "Kälteanlagen, Maschinenkühlung"
        },
        {
          "description" : "Labor – Präzisionsklimaanlage"
        },
      ],
      "image": Montan
    },
    {
      "name" : "AVORIS Fernwärme",
      "services": [
        {
          "description" : "Heizung und Sanitärinstallation 2018"
        },
      ],
      "image": Avoris
    },
    {
      "name" : "BG BRG Leoben",
      "services": [
        {
          "description" : "Heizung, Lüftung und Sanitär 2018"
        },
        
      ],
      "image": Brg
    },
    {
      "name" : "Forschungsprojekt",
      "services": [
        {
          "description" : ""
        },
        
      ],
      "image": Team6
    },
    {
      "name" : "Motel24seven",
      "copyright": "©Stefan Kristoferitsch, 2019",
      "link": "https://www.motel24seven.at/de/",
      "services": [
        {
          "description" : "Bäder, Sanitärinstallation und Lüftung 2018"
        }
      ],
      "image": Motel24Seven
    },
    {
      "name" : "EU-Projekt TESSe2b Kapfenberg",
      "services": [
        {
          "description" : ""
        }
      ],
      "image": EU
    },
    {
      "name" : "Klipp Friseur",
      "services": [
        {
          "description" : ""
        }
      ],
      "image": Klipp
    },
  ],
  referenceListSmall: [
    {
      "name": "Bundesimmobiliengesellschaft",
      "description": ""
    },
    {
      "name": "Bundespolizeikommandos Murau, Judenburg, Bruck/Mur, Mürzzuschlag, Liezen",
      "description": ""
    },
    {
      "name": "Post Immobilien",
      "description": ""
    },
    {
      "name": "Volksbank Leoben",
      "description": ""
    },
    {
      "name": "Raiffeisenbank Trofaiach",
      "description": ""
    },
    {
      "name": "Wilfing Hoch- u. Tiefbau",
      "description": ""
    },
    {
      "name": "Brigl & Bergmeister",
      "description": ""
    },
    {
      "name": "Institut für bildgebende Diagnostik – Dr. Stampfel",
      "description": ""
    },
    {
      "name": "Golf-Fashion Club und Restaurant mit Wohnung in Schardorf",
      "description": ""
    },
    {
      "name": "LKH Bruck an der Mur",
      "description": ""
    },
    {
      "name": "Akademie der Wissenschaften",
      "description": ""
    },
    {
      "name": "Gemeinde Proleb",
      "description": ""
    },
    {
      "name": "Österreichische Wohnbaugenossenschaft - ÖWG",
      "description": ""
    },
    {
      "name": "Stmk. Landesregierung",
      "description": ""
    },
    {
      "name": "Pongratz Trailer Group",
      "description": ""
    },
    {
      "name": "Immorent Süd",
      "description": ""
    },
    {
      "name": "Coca – Cola Graz",
      "description": ""
    },
    {
      "name": "ASH – DEC Umwelttechnik Wien",
      "description": ""
    },
    {
      "name": "Hausinstallationen (HKLS) von Privatkunden",
      "description": ""
    },
  ]
}

export default referenceData;