import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles, Grid, Typography } from '@material-ui/core';
import generalData from '../../data/general';
import { SocialIcon } from 'react-social-icons';

const styles = (theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      height: '400px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '250px',
    },
    //backgroundColor: 'rgb(97,167,165)',
    background:
      'linear-gradient(rgba(97, 167, 165, 1), rgba(97, 167, 165, 0.8))',
  },
  debug: {
    //border: '2px solid red'
  },
  timesGrid: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
      marginLeft: '20px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
      marginLeft: '50px',
    },
  },
  contactGrid: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
      marginLeft: '20px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
    },
  },
  link: {
    color: 'inherit',
  },
  agbGrid: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '20px',
      marginTop: '20px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '50px',
    },
  },
});

class Footer extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={3} className={classes.timesGrid}>
            <Typography variant="subtitle1">Öffnungszeiten:</Typography>
            <Typography variant="caption" color="textPrimary">
              <b>Montag - Donnerstag:</b>
              <br />
              {generalData.openings.mondayToTuesday}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.contactGrid}>
            <Typography variant="subtitle1">Kontakt:</Typography>
            <Typography variant="caption" color="textPrimary">
              <b>{generalData.impressum.companyName}</b>
              <br />
              {generalData.impressum.address}
              <br />
              {generalData.impressum.zip}
              <br />
              Email:&nbsp;
              <a
                href={`tel:${generalData.impressum.mail}`}
                className={classes.link}
              >
                {generalData.impressum.mail}
              </a>
              <br />
              Tel:&nbsp;
              <a
                href={`tel:${generalData.impressum.phone}`}
                className={classes.link}
              >
                {generalData.impressum.phone}
              </a>
              <br />
              Fax:&nbsp;{generalData.impressum.fax}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.contactGrid}>
            <Typography variant="subtitle1">Folgen Sie uns:</Typography>
            <SocialIcon url="https://www.facebook.com/haustechnikmitzukunft/" />
            <SocialIcon url="https://www.instagram.com/leitoldgmbh/" />
            <SocialIcon url="https://www.linkedin.com/in/peter-leitold-489046121/" />
          </Grid>
          <Grid item xs={12} className={classes.agbGrid}>
            <Typography variant="body2">
              © {new Date().getFullYear()} LEITOLD GmbH -{' '}
              <Link to="/agb" className={classes.link}>
                AGBs
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Footer);
