import React from 'react';

const Configurator = () => {
  React.useEffect(() => {
    const handleFrameResize = (e) => {
      setTimeout(function () {
        var frame = document.getElementsByClassName('th__iframe')[0];
        if (frame && e.data) {
          frame.style['height'] = parseInt(e.data) + 25 + 'px';
        }
      }, 60);
    };

    window.addEventListener('message', handleFrameResize, false);

    return () => {
      window.removeEventListener('message', handleFrameResize);
    };
  }, []);

  return (
    <div>
      <iframe
        scrolling="no"
        class="th__iframe"
        style={{
          border: 0,
          width: '100%',
          height: '700px',
          overflow: 'hidden',
        }}
        src="https://s3.eu-central-1.amazonaws.com/thermregio/embed_v2/index.html#/?embedded=true&id=345&custom=1&c_p=72A3A2&c_s=FEC733&c_t=848484&c_b=72A3A2&c_bh=FEC733&c_ft=848484"
      />
    </div>
  );
};

export default Configurator;
