import React from 'react';
import serviceData from '../../../data/services';
import ContentPage from './contentPage';
import { Water1, Water2 } from '../../../assets/index';

class Water extends React.Component {
  

  render() {

    return (
      <ContentPage 
        headline={serviceData.services[4].name}
        infoText={serviceData.services[4].text}
        pic1={Water1}
        pic2={Water2}
      />
    );
  }
}

export default Water;