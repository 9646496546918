const generalData = {
  'impressum': {
    'companyName': 'Leitold GmbH',
    'ceo': 'Ing. Peter Leitold',
    'address': 'Kärntnerstraße 173',
    'zip': 'A-8700 Leoben',
    'phone': '+433842424320',
    'fax': '+43 (0) 3842 / 42 4 32 - 9',
    'mail': 'office@leitold.co.at',
  },
  'openings': {
    'mondayToTuesday': '09:00–12:00 und 14:00–16:00',
    'friday': '09:00–11:30'
  }
}

export default generalData;