import './dsgvo.css';
import React from 'react';

const Dsgvo = () => {
  return (
    <div className="dsgvo-container">
      <h1>Datenschutzerklärung</h1>
      <h2 id="einleitung-ueberblick">Einleitung und Überblick</h2>
      <p>
        Wir haben diese Datenschutzerklärung (Fassung 26.02.2024-112735006)
        verfasst, um Ihnen gemäß der Vorgaben der{' '}
        <a
          href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112735006#d1e2269-1-1"
          target="_blank"
          rel="noopener"
        >
          Datenschutz-Grundverordnung (EU) 2016/679
        </a>{' '}
        und anwendbaren nationalen Gesetzen zu erklären, welche
        personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und
        die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211;
        verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen
        Möglichkeiten Sie haben. Die verwendeten Begriffe sind
        geschlechtsneutral zu verstehen.
        <br />
        <strong>Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten,
        die wir über Sie verarbeiten.
      </p>
      <p>
        Datenschutzerklärungen klingen für gewöhnlich sehr technisch und
        verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll
        Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie
        möglich beschreiben. Soweit es der Transparenz förderlich ist, werden
        technische
        <strong>Begriffe leserfreundlich erklärt</strong>, Links zu
        weiterführenden Informationen geboten und <strong>Grafiken</strong> zum
        Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache,
        dass wir im Rahmen unserer Geschäftstätigkeiten nur dann
        personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche
        Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst
        knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie
        im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe,
        Sie finden die folgenden Erläuterungen interessant und informativ und
        vielleicht ist die eine oder andere Information dabei, die Sie noch
        nicht kannten.
        <br />
        Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten
        bzw. im Impressum genannte verantwortliche Stelle zu wenden, den
        vorhandenen Links zu folgen und sich weitere Informationen auf
        Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich
        auch im Impressum.
      </p>
      <h2 id="anwendungsbereich">Anwendungsbereich</h2>
      <p>
        Diese Datenschutzerklärung gilt für alle von uns im Unternehmen
        verarbeiteten personenbezogenen Daten und für alle personenbezogenen
        Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten.
        Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4
        Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische
        Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt
        dafür, dass wir unsere Dienstleistungen und Produkte anbieten und
        abrechnen können, sei es online oder offline. Der Anwendungsbereich
        dieser Datenschutzerklärung umfasst:
      </p>
      <ul>
        <li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
        <li>Social Media Auftritte und E-Mail-Kommunikation</li>
        <li>mobile Apps für Smartphones und andere Geräte</li>
      </ul>
      <p>
        <strong>Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle
        Bereiche, in denen personenbezogene Daten im Unternehmen über die
        genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb
        dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie
        gegebenenfalls gesondert informieren.
      </p>
      <h2 id="rechtsgrundlagen">Rechtsgrundlagen</h2>
      <p>
        In der folgenden Datenschutzerklärung geben wir Ihnen transparente
        Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den
        Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen,
        personenbezogene Daten zu verarbeiten.
        <br />
        Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU)
        2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016.
        Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich
        online auf EUR-Lex, dem Zugang zum EU-Recht, unter{' '}
        <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">
          https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679
        </a>{' '}
        nachlesen.
      </p>
      <p>
        Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden
        Bedingungen zutrifft:
      </p>
      <ol>
        <li>
          <strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie
          haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck
          zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen
          Daten eines Kontaktformulars.
        </li>
        <li>
          <strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen
          Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen,
          verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag
          mit Ihnen abschließen, benötigen wir vorab personenbezogene
          Informationen.
        </li>
        <li>
          <strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c
          DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen,
          verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich
          verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese
          enthalten in der Regel personenbezogene Daten.
        </li>
        <li>
          <strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f
          DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht
          einschränken, behalten wir uns die Verarbeitung personenbezogener
          Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um
          unsere Website sicher und wirtschaftlich effizient betreiben zu
          können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
        </li>
      </ol>
      <p>
        Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen
        Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz
        lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit
        eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an
        der entsprechenden Stelle ausgewiesen.
      </p>
      <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
      <ul>
        <li>
          In <strong>Österreich</strong> ist dies das Bundesgesetz zum Schutz
          natürlicher Personen bei der Verarbeitung personenbezogener Daten (
          <strong>Datenschutzgesetz</strong>), kurz <strong>DSG</strong>.
        </li>
        <li>
          In <strong>Deutschland</strong> gilt das
          <strong>Bundesdatenschutzgesetz</strong>, kurz<strong> BDSG</strong>.
        </li>
      </ul>
      <p>
        Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen,
        informieren wir Sie in den folgenden Abschnitten darüber.
      </p>
      <h2 id="kontaktdaten-verantwortliche">
        Kontaktdaten des Verantwortlichen
      </h2>
      <p>
        Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung
        personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten
        der verantwortlichen Person bzw. Stelle:
        <br />
        <span style={{ fontWeight: 400 }}>
          LEITOLD GmbH
          <br />
          Ing. Peter Leitold
          <br />
          Kärntnerstraße 173
          <br />
          A-8700 Leoben
        </span>
      </p>
      <p>
        E-Mail: <a href="mailto:office@leitold.co.at">office@leitold.co.at</a>{' '}
        <br />
        Telefon: <a href="tel:00433842424320">+43 3842 / 42 4 32 – 0</a> <br />
        Impressum:
        <a href="https://www.leitold.co.at/impressum">
          https://www.leitold.co.at/impressum
        </a>{' '}
      </p>
      <h2 id="speicherdauer">Speicherdauer</h2>
      <p>
        Dass wir personenbezogene Daten nur so lange speichern, wie es für die
        Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig
        ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir
        personenbezogene Daten löschen, sobald der Grund für die
        Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir
        gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des
        ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der
        Buchführung.
      </p>
      <p>
        Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur
        Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und
        soweit keine Pflicht zur Speicherung besteht, gelöscht.
      </p>
      <p>
        Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir
        Sie weiter unten, sofern wir weitere Informationen dazu haben.
      </p>
      <h2 id="rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</h2>
      <p>
        Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden
        Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten
        Verarbeitung von Daten kommt:
      </p>
      <ul>
        <li>
          Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir
          Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht
          darauf eine Kopie der Daten zu erhalten und die folgenden
          Informationen zu erfahren:
          <ul>
            <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
            <li>
              die Kategorien, also die Arten von Daten, die verarbeitet werden;
            </li>
            <li>
              wer diese Daten erhält und wenn die Daten an Drittländer
              übermittelt werden, wie die Sicherheit garantiert werden kann;
            </li>
            <li>wie lange die Daten gespeichert werden;</li>
            <li>
              das Bestehen des Rechts auf Berichtigung, Löschung oder
              Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die
              Verarbeitung;
            </li>
            <li>
              dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links
              zu diesen Behörden finden Sie weiter unten);
            </li>
            <li>
              die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben
              haben;
            </li>
            <li>
              ob Profiling durchgeführt wird, ob also Daten automatisch
              ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu
              gelangen.
            </li>
          </ul>
        </li>
        <li>
          Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten,
          was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler
          finden.
        </li>
        <li>
          Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
          Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer
          Daten verlangen dürfen.
        </li>
        <li>
          Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
          Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern
          dürfen aber nicht weiter verwenden.
        </li>
        <li>
          Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit,
          was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen
          Format zur Verfügung stellen.
        </li>
        <li>
          Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
          Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
          <ul>
            <li>
              Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
              (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder
              Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können
              Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach
              so rasch wie möglich, ob wir diesem Widerspruch rechtlich
              nachkommen können.
            </li>
            <li>
              Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
              jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir
              dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.
            </li>
            <li>
              Werden Daten verwendet, um Profiling zu betreiben, können Sie
              jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir
              dürfen Ihre Daten danach nicht mehr für Profiling verwenden.
            </li>
          </ul>
        </li>
        <li>
          Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
          ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel
          Profiling) beruhenden Entscheidung unterworfen zu werden.
        </li>
        <li>
          Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt,
          Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn
          Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen
          Daten gegen die DSGVO verstößt.
        </li>
      </ul>
      <p>
        <strong>Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht,
        die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!
      </p>
      <p>
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
        Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in
        sonst einer Weise verletzt worden sind, können Sie sich bei der
        Aufsichtsbehörde beschweren. Diese ist für Österreich die
        Datenschutzbehörde, deren Website Sie unter{' '}
        <a
          href="https://www.dsb.gv.at/?tid=112735006"
          target="_blank"
          rel="noopener"
        >
          https://www.dsb.gv.at/
        </a>{' '}
        finden. In Deutschland gibt es für jedes Bundesland einen
        Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die{' '}
        <a
          href="https://www.bfdi.bund.de/DE/Home/home_node.html"
          target="_blank"
          rel="noopener"
        >
          Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
          (BfDI)
        </a>{' '}
        wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde
        zuständig:
      </p>
      <h2 id="oesterreich-datenschutzbehoerde">
        Österreich Datenschutzbehörde
      </h2>
      <p>
        <strong>Leiterin: </strong>Mag. Dr. Andrea Jelinek
        <strong>
          <br />
          Adresse:{' '}
        </strong>
        Barichgasse 40-42, 1030 Wien
        <strong>
          <br />
          Telefonnr.:{' '}
        </strong>
        +43 1 52 152-0
        <strong>
          <br />
          E-Mail-Adresse:
        </strong>{' '}
        <a href="mailto:dsb@dsb.gv.at" target="_blank" rel="noopener">
          dsb@dsb.gv.at
        </a>{' '}
        <strong>
          <br />
          Website:
        </strong>{' '}
        <a href="https://www.dsb.gv.at/" target="_blank" rel="noopener">
          https://www.dsb.gv.at/
        </a>{' '}
      </p>
      <h2 id="datenuebertragung-drittlaender">
        Datenübertragung in Drittländer
      </h2>
      <p>
        Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb des
        Geltungsbereichs der DSGVO (Drittländer), wenn Sie in diese Verarbeitung
        einwilligen oder eine sonstige gesetzliche Erlaubnis besteht. Dies
        trifft insbesondere zu, wenn die Verarbeitung gesetzlich vorgeschrieben
        oder zur Erfüllung eines Vertragsverhältnisses notwendig und in jedem
        Fall nur soweit dies generell erlaubt ist. Ihre Zustimmung ist in den
        meisten Fällen der wichtigste Grund, dass wir Daten in Drittländern
        verarbeiten lassen. Die Verarbeitung personenbezogener Daten in
        Drittländern wie den USA, wo viele Softwarehersteller Dienstleistungen
        anbieten und Ihre Serverstandorte haben, kann bedeuten, dass
        personenbezogene Daten auf unerwartete Weise verarbeitet und gespeichert
        werden.
      </p>
      <p>
        Wir weisen ausdrücklich darauf hin, dass nach Meinung des Europäischen
        Gerichtshofs derzeit nur dann ein angemessenes Schutzniveau für den
        Datentransfer in die USA besteht, wenn ein US-Unternehmen, das
        personenbezogene Daten von EU-Bürgern in den USA verarbeitet, aktiver
        Teilnehmer des EU-US Data Privacy Frameworks ist. Mehr Informationen
        dazu finden Sie unter:{' '}
        <a
          href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
          target="_blank"
          rel="follow noopener"
        >
          https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en
        </a>{' '}
      </p>
      <p>
        Die Datenverarbeitung durch US-Dienste, die nicht aktive Teilnehmer des
        EU-US Data Privacy Frameworks sind, kann dazu führen, dass
        gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert
        werden. Ferner können gegebenenfalls US-amerikanische staatliche
        Behörden Zugriff auf einzelne Daten nehmen. Zudem kann es vorkommen,
        dass erhobene Daten mit Daten aus anderen Diensten desselben Anbieters,
        sofern Sie ein entsprechendes Nutzerkonto haben, verknüpft werden. Nach
        Möglichkeit versuchen wir Serverstandorte innerhalb der EU zu nutzen,
        sofern das angeboten wird.
        <br />
        Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung
        genauer über Datenübertragung in Drittländer, sofern diese zutrifft.
      </p>
      <h2 id="sicherheit-datenverarbeitung">
        Sicherheit der Datenverarbeitung
      </h2>
      <p>
        Um personenbezogene Daten zu schützen, haben wir sowohl technische als
        auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist,
        verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch
        machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich,
        dass Dritte aus unseren Daten auf persönliche Informationen schließen
        können.
      </p>
      <p>
        Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch
        Technikgestaltung und durch datenschutzfreundliche
        Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software
        (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum)
        immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im
        Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen
        ein.
      </p>
      <h2 id="tls-verschluesselung-https">TLS-Verschlüsselung mit https</h2>
      <p>
        TLS, Verschlüsselung und https klingen sehr technisch und sind es auch.
        Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für
        „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im
        Internet zu übertragen.
        <br />
        Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem
        Browser zu unserem Webserver abgesichert ist &#8211; niemand kann
        &#8220;mithören&#8221;.
      </p>
      <p>
        Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und
        erfüllen den Datenschutz durch Technikgestaltung ({' '}
        <a
          href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112735006"
          target="_blank"
          rel="noopener"
        >
          Artikel 25 Absatz 1 DSGVO
        </a>{' '}
        ). Durch den Einsatz von TLS (Transport Layer Security), einem
        Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet,
        können wir den Schutz vertraulicher Daten sicherstellen.
        <br />
        Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am
        kleinen Schlosssymbol
        <img
          role="img"
          src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg"
          width="17"
          height="18"
        />
        links oben im Browser, links von der Internetadresse (z. B.
        beispielseite.de) und der Verwendung des Schemas https (anstatt http)
        als Teil unserer Internetadresse.
        <br />
        Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir
        die Google Suche nach &#8220;Hypertext Transfer Protocol Secure
        wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.
      </p>
      <h2 id="kommunikation">Kommunikation</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>Kommunikation Zusammenfassung</strong>
              <br />
              &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder
              Online-Formular kommunizieren
              <br />
              &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name,
              E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu
              finden Sie bei der jeweils eingesetzten Kontaktart
              <br />
              &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden,
              Geschäftspartnern usw.
              <br />
              &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der
              gesetzlichen Vorschriften
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs.
              1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder
        Online-Formular kommunizieren, kann es zur Verarbeitung
        personenbezogener Daten kommen.
      </p>
      <p>
        Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des
        damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während
        eben solange gespeichert bzw. solange es das Gesetz vorschreibt.
      </p>
      <h3>Betroffene Personen</h3>
      <p>
        Von den genannten Vorgängen sind alle betroffen, die über die von uns
        bereit gestellten Kommunikationswege den Kontakt zu uns suchen.
      </p>
      <h3>Telefon</h3>
      <p>
        Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät
        und beim eingesetzten Telekommunikationsanbieter pseudonymisiert
        gespeichert. Außerdem können Daten wie Name und Telefonnummer im
        Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert
        werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet
        wurde und es gesetzliche Vorgaben erlauben.
      </p>
      <h3>E-Mail</h3>
      <p>
        Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls
        auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;)
        gespeichert und es kommt zur Speicherung von Daten auf dem
        E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall
        beendet wurde und es gesetzliche Vorgaben erlauben.
      </p>
      <h3>Online Formulare</h3>
      <p>
        Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf
        unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse
        von uns weitergeleitet. Die Daten werden gelöscht, sobald der
        Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
      </p>
      <h3>Rechtsgrundlagen</h3>
      <p>
        Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:
      </p>
      <ul>
        <li>
          Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die
          Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall
          betreffende Zwecke zu verwenden;
        </li>
        <li>
          Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für
          die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter
          wie z. B. dem Telefonanbieter oder wir müssen die Daten für
          vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines
          Angebots, verarbeiten;
        </li>
        <li>
          Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen
          Kundenanfragen und geschäftliche Kommunikation in einem
          professionellen Rahmen betreiben. Dazu sind gewisse technische
          Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und
          Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben
          zu können.
        </li>
      </ul>
      <h2 id="cookies">Cookies</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>Cookies Zusammenfassung</strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu
              finden Sie weiter unten bzw. beim Hersteller der Software, der das
              Cookie setzt.
              <br />
              &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten
              Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
              Hersteller der Software, der das Cookie setzt.
              <br />
              &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von
              Stunden bis hin zu Jahren variieren
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was sind Cookies?</h3>
      <p>
        Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu
        speichern.
        <br />
        Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt
        werden, damit Sie die folgende Datenschutzerklärung besser verstehen.
      </p>
      <p>
        Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser.
        Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet
        Explorer und Microsoft Edge. Die meisten Websites speichern kleine
        Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
      </p>
      <p>
        Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche
        Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen
        sind es HTTP-Cookies, da es auch noch andere Cookies für andere
        Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von
        unserer Website auf Ihrem Computer gespeichert werden. Diese
        Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem
        &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus
        einem Namen und einem Wert. Bei der Definition eines Cookies müssen
        zusätzlich ein oder mehrere Attribute angegeben werden.
      </p>
      <p>
        Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise
        Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite
        wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
        Informationen an unsere Seite zurück. Dank der Cookies weiß unsere
        Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt
        sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen
        wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei
        gespeichert.
      </p>
      <p>
        Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem
        Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der
        Webbrowser eine Website an und erhält vom Server ein Cookie zurück,
        welches der Browser erneut verwendet, sobald eine andere Seite
        angefordert wird.
      </p>
      <p>
        <img
          role="img"
          src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
          alt="HTTP Cookie Interaktion zwischen Browser und Webserver"
          width="100%"
        />
      </p>
      <p>
        Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies.
        Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
        Drittanbieter-Cookies werden von Partner-Websites (z.B. Google
        Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes
        Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies
        variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind
        keine Software-Programme und enthalten keine Viren, Trojaner oder andere
        „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs
        zugreifen.
      </p>
      <p>So können zum Beispiel Cookie-Daten aussehen:</p>
      <p>
        <strong>Name:</strong> _ga
        <br />
        <strong>Wert:</strong> GA1.2.1326744211.152112735006-9
        <br />
        <strong>Verwendungszweck:</strong> Unterscheidung der Websitebesucher
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
      <ul>
        <li>Mindestens 4096 Bytes pro Cookie</li>
        <li>Mindestens 50 Cookies pro Domain</li>
        <li>Mindestens 3000 Cookies insgesamt</li>
      </ul>
      <h3>Welche Arten von Cookies gibt es?</h3>
      <p>
        Die Frage welche Cookies wir im Speziellen verwenden, hängt von den
        verwendeten Diensten ab und wird in den folgenden Abschnitten der
        Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
        verschiedenen Arten von HTTP-Cookies eingehen.
      </p>
      <p>Man kann 4 Arten von Cookies unterscheiden:</p>
      <p>
        <strong>
          Unerlässliche Cookies
          <br />{' '}
        </strong>
        Diese Cookies sind nötig, um grundlegende Funktionen der Website
        sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User
        ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
        und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb
        nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
      </p>
      <p>
        <strong>
          Zweckmäßige Cookies
          <br />{' '}
        </strong>
        Diese Cookies sammeln Infos über das Userverhalten und ob der User
        etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies
        auch die Ladezeit und das Verhalten der Website bei verschiedenen
        Browsern gemessen.
      </p>
      <p>
        <strong>
          Zielorientierte Cookies
          <br />{' '}
        </strong>
        Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
        Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
        Formulardaten gespeichert.
      </p>
      <p>
        <strong>
          Werbe-Cookies
          <br />{' '}
        </strong>
        Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem
        User individuell angepasste Werbung zu liefern. Das kann sehr praktisch,
        aber auch sehr nervig sein.
      </p>
      <p>
        Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt,
        welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese
        Entscheidung auch in einem Cookie gespeichert.
      </p>
      <p>
        Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen
        nicht scheuen, empfehlen wir{' '}
        <a href="https://datatracker.ietf.org/doc/html/rfc6265">
          https://datatracker.ietf.org/doc/html/rfc6265
        </a>{' '}
        , dem Request for Comments der Internet Engineering Task Force (IETF)
        namens &#8220;HTTP State Management Mechanism&#8221;.
      </p>
      <h3>Zweck der Verarbeitung über Cookies</h3>
      <p>
        Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details
        dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das
        Cookie setzt.
      </p>
      <h3>Welche Daten werden verarbeitet?</h3>
      <p>
        Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben.
        Welche Daten in Cookies gespeichert werden, kann man leider nicht
        verallgemeinern, aber wir werden Sie im Rahmen der folgenden
        Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten
        informieren.
      </p>
      <h3>Speicherdauer von Cookies</h3>
      <p>
        Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter
        präzisiert. Manche Cookies werden nach weniger als einer Stunde
        gelöscht, andere können mehrere Jahre auf einem Computer gespeichert
        bleiben.
      </p>
      <p>
        Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können
        über ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe
        auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die
        auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer
        Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis
        dahin unberührt bleibt.
      </p>
      <h3>Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
      <p>
        Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
        Unabhängig von welchem Service oder welcher Website die Cookies stammen,
        haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder
        nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von
        Drittanbietern blockieren, aber alle anderen Cookies zulassen.
      </p>
      <p>
        Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser
        gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen
        wollen, können Sie dies in Ihren Browser-Einstellungen finden:
      </p>
      <p>
        {' '}
        <a
          href="https://support.google.com/chrome/answer/95647?tid=112735006"
          target="_blank"
          rel="noopener noreferrer"
        >
          Chrome: Cookies in Chrome löschen, aktivieren und verwalten
        </a>{' '}
      </p>
      <p>
        {' '}
        <a
          href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=112735006"
          target="_blank"
          rel="noopener noreferrer"
        >
          Safari: Verwalten von Cookies und Websitedaten mit Safari
        </a>{' '}
      </p>
      <p>
        {' '}
        <a
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=112735006"
          target="_blank"
          rel="noopener noreferrer"
        >
          Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
          Ihrem Computer abgelegt haben
        </a>{' '}
      </p>
      <p>
        {' '}
        <a href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=112735006">
          Internet Explorer: Löschen und Verwalten von Cookies
        </a>{' '}
      </p>
      <p>
        {' '}
        <a href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=112735006">
          Microsoft Edge: Löschen und Verwalten von Cookies
        </a>{' '}
      </p>
      <p>
        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
        Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
        gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
        entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise
        ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in
        Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies
        deaktivieren Chrome&#8221; im Falle eines Chrome Browsers.
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist
        festgehalten, dass das Speichern von Cookies eine
        <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen
        verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr
        unterschiedliche Reaktionen auf diese Richtlinien. In Österreich
        erfolgte aber die Umsetzung dieser Richtlinie in § 165 Abs. 3 des
        Telekommunikationsgesetzes (2021). In Deutschland wurden die
        Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen
        erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des
        Telemediengesetzes (TMG).
      </p>
      <p>
        Für unbedingt notwendige Cookies, auch soweit keine Einwilligung
        vorliegt, bestehen <strong>berechtigte Interessen</strong> (Artikel 6
        Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher Natur
        sind. Wir möchten den Besuchern der Website eine angenehme
        Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft
        unbedingt notwendig.
      </p>
      <p>
        Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen,
        geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist
        insoweit Art. 6 Abs. 1 lit. a DSGVO.
      </p>
      <p>
        In den folgenden Abschnitten werden Sie genauer über den Einsatz von
        Cookies informiert, sofern eingesetzte Software Cookies verwendet.
      </p>
      <h2 id="webhosting-einleitung">Webhosting Einleitung</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>Webhosting Zusammenfassung</strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: professionelles Hosting der Website und
              Absicherung des Betriebs
              <br />
              &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des
              Websitebesuchs, verwendeter Browser und weitere Daten. Mehr
              Details dazu finden Sie weiter unten bzw. beim jeweils
              eingesetzten Webhosting Provider.
              <br />
              &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in
              der Regel 2 Wochen
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO
              (Berechtigte Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was ist Webhosting?</h3>
      <p>
        Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen
        &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und
        gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst
        sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen
        wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles
        von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie
        dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder
        musterbeispiel.com.
      </p>
      <p>
        Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen
        möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt.
        Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome,
        Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser
        oder Webbrowser dazu.
      </p>
      <p>
        Um die Website anzuzeigen, muss sich der Browser zu einem anderen
        Computer verbinden, wo der Code der Website gespeichert ist: dem
        Webserver. Der Betrieb eines Webservers ist eine komplizierte und
        aufwendige Aufgabe, weswegen dies in der Regel von professionellen
        Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an
        und sorgen damit für eine verlässliche und fehlerfreie Speicherung der
        Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben
        Sie dran, es wird noch besser!
      </p>
      <p>
        Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop,
        Laptop, Tablet oder Smartphone) und während der Datenübertragung zu und
        vom Webserver kann es zu einer Verarbeitung personenbezogener Daten
        kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch
        der Webserver Daten eine Zeit lang speichern, um einen ordentlichen
        Betrieb zu gewährleisten.
      </p>
      <p>
        Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur
        Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und
        dem Hosting-Provider.
      </p>
      <p>
        <img
          role="img"
          src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
          alt="Browser und Webserver"
          width="100%"
        />
      </p>
      <h3>Warum verarbeiten wir personenbezogene Daten?</h3>
      <p>Die Zwecke der Datenverarbeitung sind:</p>
      <ol>
        <li>
          Professionelles Hosting der Website und Absicherung des Betriebs
        </li>
        <li>zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
        <li>
          Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres
          Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen
        </li>
      </ol>
      <h3>Welche Daten werden verarbeitet?</h3>
      <p>
        Auch während Sie unsere Website jetzt gerade besuchen, speichert unser
        Webserver, das ist der Computer auf dem diese Webseite gespeichert ist,
        in der Regel automatisch Daten wie
      </p>
      <ul>
        <li>die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
        <li>Browser und Browserversion (z. B. Chrome 87)</li>
        <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
        <li>
          die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.{' '}
          <a
            href="https://www.beispielquellsite.de/vondabinichgekommen/"
            target="_blank"
            rel="follow noopener"
          >
            https://www.beispielquellsite.de/vondabinichgekommen/
          </a>{' '}
          )
        </li>
        <li>
          den Hostnamen und die IP-Adresse des Geräts von welchem aus
          zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)
        </li>
        <li>Datum und Uhrzeit</li>
        <li>in Dateien, den sogenannten Webserver-Logfiles</li>
      </ul>
      <h3>Wie lange werden Daten gespeichert?</h3>
      <p>
        In der Regel werden die oben genannten Daten zwei Wochen gespeichert und
        danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können
        jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
        rechtswidrigem Verhalten von Behörden eingesehen werden.
      </p>
      <p>
        <strong>Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider
        (Firma, die unsere Website auf speziellen Computern (Servern) laufen
        lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung
        weiter!
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen
        des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der
        berechtigten Interessen), denn die Nutzung von professionellem Hosting
        bei einem Provider ist notwendig, um das Unternehmen im Internet sicher
        und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus
        gegebenenfalls verfolgen zu können.
      </p>
      <p>
        Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag
        über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung
        von Datenschutz gewährleistet und Datensicherheit garantiert.
      </p>
      <h2 id="web-analytics-einleitung">Web Analytics Einleitung</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>
                Web Analytics Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: Auswertung der Besucherinformationen zur
              Optimierung des Webangebots.
              <br />
              &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
              Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt,
              Navigationsverhalten, Klickverhalten und IP-Adressen enthalten.
              Mehr Details dazu finden Sie beim jeweils eingesetzten Web
              Analytics Tool.
              <br />
              &#x1f4c5; Speicherdauer: abhängig vom eingesetzten
              Web-Analytics-Tool
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was ist Web Analytics?</h3>
      <p>
        Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens
        der Website-Besucher, kurz Web Analytics oder Web-Analyse genannt. Dabei
        werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch
        Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe
        der Daten werden Analysen über das Nutzerverhalten auf unserer Website
        erstellt und uns als Websitebetreiber zur Verfügung gestellt. Zusätzlich
        bieten die meisten Tools verschiedene Testmöglichkeiten an. So können
        wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am
        besten ankommen. Dafür zeigen wir Ihnen für einen begrenzten
        Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter
        A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere
        Websitebesucher interessanter finden. Für solche Testverfahren, wie auch
        für andere Analytics-Verfahren, können auch Userprofile erstellt werden
        und die Daten in Cookies gespeichert werden.
      </p>
      <h3>Warum betreiben wir Web Analytics?</h3>
      <p>
        Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen für
        unsere Branche das beste Webangebot auf dem Markt liefern. Um dieses
        Ziel zu erreichen, wollen wir einerseits das beste und interessanteste
        Angebot bieten und andererseits darauf achten, dass Sie sich auf unserer
        Website rundum wohlfühlen. Mit Hilfe von Webanalyse-Tools können wir das
        Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann
        entsprechend unser Webangebot für Sie und uns verbessern. So können wir
        beispielsweise erkennen wie alt unsere Besucher durchschnittlich sind,
        woher sie kommen, wann unsere Website am meisten besucht wird oder
        welche Inhalte oder Produkte besonders beliebt sind. All diese
        Informationen helfen uns die Website zu optimieren und somit bestens an
        Ihre Bedürfnisse, Interessen und Wünsche anzupassen.
      </p>
      <h3>Welche Daten werden verarbeitet?</h3>
      <p>
        Welche Daten genau gespeichert werden, hängt natürlich von den
        verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel
        gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche
        Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen, welchen
        Browser sie verwenden, mit welchem Gerät (PC, Tablet, Smartphone usw.)
        Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn
        Sie damit einverstanden waren, dass auch Standortdaten erhoben werden
        dürfen, können auch diese durch den Webanalyse-Tool-Anbieter verarbeitet
        werden.
      </p>
      <p>
        Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der
        Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene
        Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert
        (also in unkenntlicher und gekürzter Form) gespeichert. Für den Zweck
        der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich
        keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder
        Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie
        erhoben werden, pseudonymisiert gespeichert. So können Sie als Person
        nicht identifiziert werden.
      </p>
      <p>
        Das folgende Beispiel zeigt schematisch die Funktionsweise von Google
        Analytics als Beispiel für client-basiertes Webtracking mit
        Java-Script-Code.
      </p>
      <p>
        <img
          role="img"
          src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg"
          alt="Schematischer Datenfluss bei Google Analytics"
          width="100%"
        />
      </p>
      <p>
        Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom
        Anbieter ab. Manche Cookies speichern Daten nur für ein paar Minuten
        bzw. bis Sie die Website wieder verlassen, andere Cookies können Daten
        über mehrere Jahre speichern.
      </p>
      <h3>
        <span
          data-sheets-value='{"1":2,"2":"Wo und wie lange werden Daten gespeichert?"}'
          data-sheets-userformat='{"2":769,"3":{"1":0},"11":4,"12":0}'
        >
          Dauer der Datenverarbeitung
        </span>
      </h3>
      <p>
        Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten,
        sofern wir weitere Informationen dazu haben. Generell verarbeiten wir
        personenbezogene Daten nur so lange wie es für die Bereitstellung
        unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es,
        wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist,
        kann diese Speicherdauer auch überschritten werden.
      </p>
      <h3>Widerspruchsrecht</h3>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das
        funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Zum Beispiel können Sie auch die Datenerfassung
        durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies
        verwalten, deaktivieren oder löschen.
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir
        mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut
        <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong>
        die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
        sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.
      </p>
      <p>
        Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes
        Interesse daran, das Verhalten der Websitebesucher zu analysieren und so
        unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von
        Web-Analytics erkennen wir Fehler der Website, können Attacken
        identifizieren und die Wirtschaftlichkeit verbessern. Die
        Rechtsgrundlage dafür ist
        <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.
        Wir setzen die Tools gleichwohl nur ein, soweit sie eine Einwilligung
        erteilt haben.
      </p>
      <p>
        Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir
        Ihnen auch das Lesen unserer allgemeinen Datenschutzerklärung zu
        Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und
        verarbeitet werden, sollten Sie die Datenschutzerklärungen der
        jeweiligen Tools durchlesen.
      </p>
      <p>
        Informationen zu speziellen Web-Analytics-Tools, erhalten Sie &#8211;
        sofern vorhanden &#8211; in den folgenden Abschnitten.
      </p>
      <h2 id="google-analytics-datenschutzerklaerung">
        Google Analytics Datenschutzerklärung
      </h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>
                Google Analytics Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: Auswertung der Besucherinformationen zur
              Optimierung des Webangebots.
              <br />
              &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie
              Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt,
              Navigationsverhalten und Klickverhalten enthalten. Mehr Details
              dazu finden Sie weiter unten in dieser Datenschutzerklärung.
              <br />
              &#x1f4c5; Speicherdauer: individuell einstellbar, standardmäßig
              speichert Google Analytics 4 Daten für 14 Monate
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was ist Google Analytics?</h3>
      <p>
        Wir verwenden auf unserer Website das Analyse-Tracking Tool Google
        Analytics in der Version Google Analytics 4 (GA4) des amerikanischen
        Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen
        Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
        für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten
        über Ihre Handlungen auf unserer Website. Durch die Kombination aus
        verschiedenen Technologien wie Cookies, Geräte-IDs und
        Anmeldeinformationen, können Sie als User aber über verschiedene Geräte
        hinweg identifiziert werden. Dadurch können Ihre Handlungen auch
        plattformübergreifend analysiert werden.
      </p>
      <p>
        Wenn Sie beispielsweise einen Link anklicken, wird dieses Ereignis in
        einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der
        Berichte, die wir von Google Analytics erhalten, können wir unsere
        Website und unseren Service besser an Ihre Wünsche anpassen. Im
        Folgenden gehen wir näher auf das Tracking-Tool ein und informieren Sie
        vor allem darüber, welche Daten verarbeitet werden und wie Sie das
        verhindern können.
      </p>
      <p>
        Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse
        unserer Website dient. Basis dieser Messungen und Analysen ist eine
        pseudonyme Nutzeridentifikationsnummer. Diese Nummer beinhaltet keine
        personenbezogenen Daten wie Name oder Adresse, sondern dient dazu,
        Ereignisse einem Endgerät zuzuordnen. GA4 nutzt ein ereignisbasiertes
        Modell, das detaillierte Informationen zu Userinteraktionen wie etwa
        Seitenaufrufe, Klicks, Scrollen, Conversion-Ereignisse erfasst. Zudem
        wurden in GA4 auch verschiedene maschinelle Lernfunktionen eingebaut, um
        das Nutzerverhalten und gewissen Trends besser zu verstehen. GA4 setzt
        mit Hilfe maschineller Lernfunktionen auf Modellierungen. Das heißt auf
        Grundlage der erhobenen Daten können auch fehlende Daten hochgerechnet
        werden, um damit die Analyse zu optimieren und auch um Prognosen geben
        zu können.
      </p>
      <p>
        Damit Google Analytics grundsätzlich funktioniert, wird ein
        Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
        Website besuchen, zeichnet dieser Code verschiedene Ereignisse auf, die
        Sie auf unserer Website ausführen. Mit dem ereignisbasierten Datenmodell
        von GA4 können wir als Websitebetreiber spezifische Ereignisse
        definieren und verfolgen, um Analysen von Userinteraktionen zu erhalten.
        Somit können neben allgemeinen Informationen wie Klicks oder
        Seitenaufrufe auch spezielle Ereignisse, die für unser Geschäft wichtig
        sind, verfolgt werden. Solche speziellen Ereignisse können zum Beispiel
        das Absenden eines Kontaktformulars oder der Kauf eines Produkts sein.
      </p>
      <p>
        Sobald Sie unsere Website verlassen, werden diese Daten an die
        Google-Analytics-Server gesendet und dort gespeichert.
      </p>
      <p>
        Google verarbeitet die Daten und wir bekommen Berichte über Ihr
        Userverhalten. Dabei kann es sich unter anderem um folgende Berichte
        handeln:
      </p>
      <ul>
        <li>
          Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User
          besser kennen und wissen genauer, wer sich für unser Service
          interessiert.
        </li>
        <li>
          Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung
          leichter analysieren und verbessern.
        </li>
        <li>
          Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche
          Informationen darüber, wie wir mehr Menschen für unseren Service
          begeistern können.
        </li>
        <li>
          Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website
          interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer
          Seite zurücklegen und welche Links Sie anklicken.
        </li>
        <li>
          Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie
          aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen.
          Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem
          Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte
          erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen
          ankommen. So wollen wir unsere Conversionrate steigern.
        </li>
        <li>
          Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf
          unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade
          diesen Text lesen.
        </li>
      </ul>
      <p>
        Neben den oben genannten Analyseberichten bietet Google Analytics 4
        unter anderem auch folgende Funktionen an:
      </p>
      <ul>
        <li>
          Ereignisbasiertes Datenmodell: Dieses Modell erfasst ganz spezifische
          Ereignisse, die auf unserer Website stattfinden können. Zum Beispiel
          das Abspielen eines Videos, der Kauf eines Produkts oder das Anmelden
          zu unserem Newsletter.
        </li>
        <li>
          Erweiterte Analysefunktionen: Mit diesen Funktionen können wir Ihr
          Verhalten auf unserer Website oder gewisse allgemeine Trends noch
          besser verstehen. So können wir etwa Usergruppen segmentieren,
          Vergleichsanalysen von Zielgruppen machen oder Ihren Weg bzw. Pfad auf
          unserer Website nachvollziehen.
        </li>
        <li>
          Vorhersagemodellierung: Auf Grundlage erhobener Daten können durch
          maschinelles Lernen fehlende Daten hochgerechnet werden, die
          zukünftige Ereignisse und Trends vorhersagen. Das kann uns helfen,
          bessere Marketingstrategien zu entwickeln.
        </li>
        <li>
          Cross-Plattform-Analyse: Die Erfassung und Analyse von Daten sind
          sowohl von Websites als auch von Apps möglich. Das bietet uns die
          Möglichkeit, das Userverhalten plattformübergreifend zu analysieren,
          sofern Sie natürlich der Datenverarbeitung eingewilligt haben.
        </li>
      </ul>
      <h3>Warum verwenden wir Google Analytics auf unserer Website?</h3>
      <p>
        Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen den
        bestmöglichen Service bieten. Die Statistiken und Daten von Google
        Analytics helfen uns dieses Ziel zu erreichen.
      </p>
      <p>
        Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den
        Stärken und Schwächen unserer Website. Einerseits können wir unsere
        Seite so optimieren, dass sie von interessierten Menschen auf Google
        leichter gefunden wird. Andererseits helfen uns die Daten, Sie als
        Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an
        unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu
        bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen
        individueller und kostengünstiger durchzuführen. Schließlich macht es
        nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die
        sich dafür interessieren.
      </p>
      <h3>Welche Daten werden von Google Analytics gespeichert?</h3>
      <p>
        Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige,
        eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt
        Sie Google Analytics als neuen User und Ihnen wird eine User-ID
        zugeordnet. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie
        als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden
        gemeinsam mit dieser User-ID gespeichert. So ist es erst möglich
        pseudonyme Userprofile auszuwerten.
      </p>
      <p>
        Um mit Google Analytics unsere Website analysieren zu können, muss eine
        Property-ID in den Tracking-Code eingefügt werden. Die Daten werden dann
        in der entsprechenden Property gespeichert. Für jede neu angelegte
        Property ist die Google Analytics 4-Property standardmäßig. Je nach
        verwendeter Property werden Daten unterschiedlich lange gespeichert.
      </p>
      <p>
        Durch Kennzeichnungen wie Cookies, App-Instanz-IDs, User-IDs oder etwa
        benutzerdefinierte Ereignisparameter werden Ihre Interaktionen, sofern
        Sie eingewilligt haben, plattformübergreifend gemessen. Interaktionen
        sind alle Arten von Handlungen, die Sie auf unserer Website ausführen.
        Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen,
        können über Google Analytics generierte Daten mit Drittanbieter-Cookies
        verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer
        wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen,
        wenn es gesetzlich erforderlich ist.
      </p>
      <p>
        Laut Google werden in Google Analytics 4 keine IP-Adressen protokolliert
        oder gespeichert. Google nutzt die IP-Adressdaten allerdings für die
        Ableitung von Standortdaten und löscht sie unmittelbar danach. Alle
        IP-Adressen, die von Usern in der EU erhoben werden, werden also
        gelöscht, bevor die Daten in einem Rechenzentrum oder auf einem Server
        gespeichert werden.
      </p>
      <p>
        Da bei Google Analytics 4 der Fokus auf ereignisbasierten Daten liegt,
        verwendet das Tool im Vergleich zu früheren Versionen (wie Google
        Universal Analytics) deutlich weniger Cookies. Dennoch gibt es einige
        spezifische Cookies, die von GA4 verwendet werden. Dazu zählen zum
        Beispiel:
      </p>
      <p>
        <strong>Name:</strong> _ga
        <br />
        <strong>Wert: </strong>2.1326744211.152112735006-5
        <br />
        <strong>Verwendungszweck:</strong> Standardmäßig verwendet analytics.js
        das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur
        Unterscheidung der Webseitenbesucher.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> _gid
        <br />
        <strong>Wert: </strong>2.1687193234.152112735006-1
        <br />
        <strong>Verwendungszweck:</strong> Das Cookie dient auch zur
        Unterscheidung der Webseitenbesucher
        <br />
        <strong>Ablaufdatum:</strong> nach 24 Stunden
      </p>
      <p>
        <strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;
        <br />
        <strong>Wert:</strong> 1<br />
        <strong>Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate
        verwendet. Wenn Google Analytics über den Google Tag Manager
        bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_
        &lt;property-id&gt;.
        <br />
        <strong>Ablaufdatum: </strong>nach 1 Minute
      </p>
      <p>
        <strong>Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf
        Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder
        auch verändert. Ziel von GA4 ist es auch, den Datenschutz zu verbessern.
        Daher bietet das Tool einige Möglichkeiten zur Kontrolle der
        Datenerfassung. So können wir beispielsweise die Speicherdauer selbst
        festlegen und auch die Datenerfassung steuern.
      </p>
      <p>
        Hier zeigen wir Ihnen einen Überblick über die wichtigsten Arten von
        Daten, die mit Google Analytics erhoben werden:
      </p>
      <p>
        <strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über
        Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen
        wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.
      </p>
      <p>
        <strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die
        Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen.
        Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.
      </p>
      <p>
        <strong>Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist
        die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann
        unsere Website wieder verlassen.
      </p>
      <p>
        <strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto
        erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese
        Daten.
      </p>
      <p>
        <strong>Standort:</strong> IP-Adressen werden in Google Analytics nicht
        protokolliert oder gespeichert. Allerdings werden kurz vor der Löschung
        der IP-Adresse Ableitungen für Standortdaten genutzt.
      </p>
      <p>
        <strong>Technische Informationen:</strong> Zu den technischen
        Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter
        oder Ihre Bildschirmauflösung.
      </p>
      <p>
        <strong>Herkunftsquelle:</strong> Google Analytics beziehungsweise uns
        interessiert natürlich auch über welche Website oder welche Werbung Sie
        auf unsere Seite gekommen sind.
      </p>
      <p>
        Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von
        Medien (z. B., wenn Sie ein Video über unsere Seite abspielen), das
        Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren
        Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient
        nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google
        Analytics.
      </p>
      <h3>Wie lange und wo werden die Daten gespeichert?</h3>
      <p>
        Google hat ihre Server auf der ganzen Welt verteilt. Hier können Sie
        genau nachlesen, wo sich die Google-Rechenzentren befinden:{' '}
        <a href="https://www.google.com/about/datacenters/locations/?hl=de">
          https://www.google.com/about/datacenters/locations/?hl=de
        </a>{' '}
      </p>
      <p>
        Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt.
        Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor
        Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt
        es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise
        die Hardware bei Google ausfällt oder Naturkatastrophen Server
        lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google
        dennoch gering.
      </p>
      <p>
        Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties
        ab. Die Speicherdauer wird stets für jede einzelne Property eigens
        festgelegt. Google Analytics bietet uns zur Kontrolle der Speicherdauer
        vier Optionen an:
      </p>
      <ul>
        <li>2 Monate: das ist die kürzeste Speicherdauer.</li>
        <li>
          14 Monate: standardmäßig bleiben die Daten bei GA4 für 14 Monate
          gespeichert.
        </li>
        <li>26 Monate: man kann die Daten auch 26 Monate lang speichern.</li>
        <li>Daten werden erst gelöscht, wenn wir sie manuell löschen</li>
      </ul>
      <p>
        Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht
        werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr
        unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer
        jedes Mal zurückgesetzt, wenn Sie unsere Website innerhalb des
        festgelegten Zeitraums wieder besuchen.
      </p>
      <p>
        Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die
        Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit
        Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
        DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf
        aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
        Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer
        größeren Einheit.
      </p>
      <h3>
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
      </h3>
      <p>
        Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht,
        Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen
        oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von
        Google Analytics-JavaScript (analytics.js, gtag.js) verhindern Sie, dass
        Google Analytics 4 Ihre Daten verwendet. Das Browser-Add-on können Sie
        unter{' '}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>{' '}
        runterladen und installieren. Beachten Sie bitte, dass durch dieses
        Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.
      </p>
      <p>
        Falls Sie grundsätzlich Cookies deaktivieren, löschen oder verwalten
        wollen, finden Sie unter dem Abschnitt „Cookies“ die entsprechenden
        Links zu den jeweiligen Anleitungen der bekanntesten Browser.
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche
        wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt
        laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die
        Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie
        bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.
      </p>
      <p>
        Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes
        Interesse daran, das Verhalten der Websitebesucher zu analysieren und so
        unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von
        Google Analytics erkennen wir Fehler der Website, können Attacken
        identifizieren und die Wirtschaftlichkeit verbessern. Die
        Rechtsgrundlage dafür ist
        <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.
        Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine
        Einwilligung erteilt haben.
      </p>
      <p>
        Google verarbeitet Daten von Ihnen u.a. auch in den USA. Google ist
        aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der
        korrekte und sichere Datentransfer personenbezogener Daten von
        EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie
        auf{' '}
        <a
          href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
          target="_blank"
          rel="follow noopener"
        >
          https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en
        </a>{' '}
        .
      </p>
      <p>
        Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46.
        Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual
        Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen
        und sollen sicherstellen, dass Ihre Daten auch dann den europäischen
        Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden.
        Durch das EU-US Data Privacy Framework und durch die
        Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung
        Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten,
        selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet
        werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der
        EU-Kommission. Sie finden den Beschluss und die entsprechenden
        Standardvertragsklauseln u.a. hier:{' '}
        <a
          href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
          target="_blank"
          rel="follow noopener"
        >
          https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
        </a>{' '}
      </p>
      <p>
        Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing
        Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie
        unter{' '}
        <a
          href="https://business.safety.google/intl/de/adsprocessorterms/"
          target="_blank"
          rel="follow noopener"
        >
          https://business.safety.google/intl/de/adsprocessorterms/
        </a>{' '}
        .
      </p>
      <p>
        Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die
        Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über
        den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links:{' '}
        <a
          href="https://marketingplatform.google.com/about/analytics/terms/de/"
          target="_blank"
          rel="follow noopener"
        >
          https://marketingplatform.google.com/about/analytics/terms/de/
        </a>{' '}
        und{' '}
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=de"
          target="_blank"
          rel="follow noopener"
        >
          https://support.google.com/analytics/answer/6004245?hl=de
        </a>{' '}
        .
      </p>
      <p>
        Wenn Sie mehr über die Datenverarbeitung erfahren wollen, nutzen Sie die
        Google-Datenschutzerklärung auf{' '}
        <a
          href="https://policies.google.com/privacy?hl=de&amp;tid=112735006"
          target="_blank"
          rel="follow noopener"
        >
          https://policies.google.com/privacy?hl=de
        </a>{' '}
        .
      </p>
      <h2 id="e-mail-marketing-einleitung">E-Mail-Marketing Einleitung</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>E-Mail-Marketing Zusammenfassung</strong>
              <br />
              &#x1f465; Betroffene: Newsletter-Abonnenten
              <br />
              &#x1f91d; Zweck: Direktwerbung per E-Mail, Benachrichtigung über
              systemrelevante Ereignisse
              <br />
              &#x1f4d3; Verarbeitete Daten: Eingegebene Daten bei der
              Registrierung jedoch mindestens die E-Mail-Adresse. Mehr Details
              dazu finden Sie beim jeweils eingesetzten E-Mail-Marketing-Tool.
              <br />
              &#x1f4c5; Speicherdauer: Dauer des Bestehens des Abonnements
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was ist E-Mail-Marketing?</h3>
      <p>
        Um Sie stets auf dem Laufenden zu halten, nutzen wir auch die
        Möglichkeit des E-Mail-Marketings. Dabei werden, sofern Sie dem Empfang
        unserer E-Mails bzw. Newsletter zugestimmt haben, auch Daten von Ihnen
        verarbeitet und gespeichert. E-Mail-Marketing ist ein Teilbereich des
        Online-Marketings. Dabei werden Neuigkeiten oder allgemeine
        Informationen über ein Unternehmen, Produkte oder Dienstleistungen per
        E-Mail an eine bestimmte Gruppe an Menschen, die sich dafür
        interessieren, gesendet.
      </p>
      <p>
        Wenn Sie an unserem E-Mail-Marketing (meist per Newsletter) teilnehmen
        wollen, müssen Sie sich im Normalfall einfach nur mit Ihrer
        E-Mail-Adresse anmelden. Dafür füllen Sie ein Online-Formular aus und
        senden es ab. Es kann aber auch vorkommen, dass wir Sie etwa um Ihre
        Anrede und Ihren Namen bitten, damit wir Sie auch persönlich anschreiben
        können.
      </p>
      <p>
        Grundsätzlich funktioniert das Anmelden zu Newslettern mit Hilfe des
        sogenannten „Double-Opt-In-Verfahrens“. Nachdem Sie sich für unseren
        Newsletter auf unserer Website angemeldet haben, bekommen Sie eine
        E-Mail, über die Sie die Newsletter-Anmeldung bestätigen. So wird
        sichergestellt, dass Ihnen die E-Mail-Adresse gehört und sich niemand
        mit einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns
        verwendetes Benachrichtigungs-Tool protokolliert jede einzelne
        Anmeldung. Dies ist nötig, damit wir den rechtlich korrekten
        Anmeldevorgang auch nachweisen können. Dabei wird in der Regel der
        Zeitpunkt der Anmeldung, der Zeitpunkt der Anmeldebestätigung und Ihre
        IP-Adresse gespeichert. Zusätzlich wird auch protokolliert, wenn Sie
        Änderungen Ihrer gespeicherten Daten vornehmen.
      </p>
      <h3>Warum nutzen wir E-Mail-Marketing?</h3>
      <p>
        Wir wollen natürlich mit Ihnen in Kontakt bleiben und Ihnen stets die
        wichtigsten Neuigkeiten über unser Unternehmen präsentieren. Dafür
        nutzen wir unter anderem E-Mail-Marketing &#8211; oft auch nur
        &#8220;Newsletter&#8221; bezeichnet &#8211; als wesentlichen Bestandteil
        unseres Online-Marketings. Sofern Sie sich damit einverstanden erklären
        oder es gesetzlich erlaubt ist, schicken wir Ihnen Newsletter,
        System-E-Mails oder andere Benachrichtigungen per E-Mail. Wenn wir im
        folgenden Text den Begriff „Newsletter“ verwenden, meinen wir damit
        hauptsächlich regelmäßig versandte E-Mails. Natürlich wollen wir Sie mit
        unseren Newsletter in keiner Weise belästigen. Darum sind wir wirklich
        stets bemüht, nur relevante und interessante Inhalte zu bieten. So
        erfahren Sie etwa mehr über unser Unternehmen, unsere Leistungen oder
        Produkte. Da wir unsere Angebote auch immer verbessern, erfahren Sie
        über unseren Newsletter auch immer, wenn es Neuigkeiten gibt oder wir
        gerade spezielle, lukrative Aktionen anbieten. Sofern wir einen
        Dienstleister, der ein professionelles Versand-Tool anbietet, für unser
        E-Mail-Marketing beauftragen, machen wir das, um Ihnen schnelle und
        sichere Newsletter bieten zu können. Zweck unseres E-Mail-Marketings ist
        grundsätzlich, Sie über neue Angebote zu informieren und auch unseren
        unternehmerischen Zielen näher zu kommen.
      </p>
      <h3>Welche Daten werden verarbeitet?</h3>
      <p>
        Wenn Sie über unsere Website Abonnent unseres Newsletters werden,
        bestätigen Sie per E-Mail die Mitgliedschaft in einer E-Mail-Liste.
        Neben IP-Adresse und E-Mail-Adresse können auch Ihre Anrede, Ihr Name,
        Ihre Adresse und Ihre Telefonnummer gespeichert werden. Allerdings nur,
        wenn Sie dieser Datenspeicherungen zustimmen. Die als solche markierten
        Daten sind notwendig, damit Sie an dem angebotenen Dienst teilnehmen
        können. Die Angabe ist freiwillig, die Nichtangabe führt jedoch dazu,
        dass Sie den Dienst nicht nutzen können. Zusätzlich können etwa auch
        Informationen zu Ihrem Gerät oder zu Ihren bevorzugten Inhalten auf
        unserer Website gespeichert werden. Mehr zur Speicherung von Daten, wenn
        Sie eine Website besuchen, finden Sie im Abschnitt &#8220;Automatische
        Datenspeicherung&#8221;. Ihre Einwilligungserklärung zeichnen wir auf,
        damit wir stets nachweisen können, dass dieser unseren Gesetzen
        entspricht.
      </p>
      <h3>Dauer der Datenverarbeitung</h3>
      <p>
        Wenn Sie Ihre E-Mail-Adresse aus unserem E-Mail/Newsletter-Verteiler
        austragen, dürfen wir Ihre Adresse bis zu drei Jahren auf Grundlage
        unserer berechtigten Interessen speichern, damit wir Ihre damalige
        Einwilligung noch nachweisen können. Verarbeiten dürfen wir diese Daten
        nur, wenn wir uns gegen etwaige Ansprüche wehren müssen.
      </p>
      <p>
        Wenn Sie allerdings bestätigen, dass Sie uns die Einwilligung zur
        Newsletter-Anmeldung gegeben haben, können Sie jederzeit einen
        individuellen Löschantrag stellen. Widersprechen Sie der Einwilligung
        dauerhaft, behalten wir uns das Recht vor, Ihre E-Mail-Adresse in einer
        Sperrliste zu speichern. Solange Sie freiwillig unseren Newsletter
        abonniert haben, solange behalten wir selbstverständlich auch Ihre
        E-Mail-Adresse.
      </p>
      <h3>Widerspruchsrecht</h3>
      <p>
        Sie haben jederzeit die Möglichkeit Ihre Newsletter-Anmeldung zu
        kündigen. Dafür müssen Sie lediglich Ihre Einwilligung zur
        Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur wenige
        Sekunden bzw. einen oder zwei Klicks. Meistens finden Sie direkt am Ende
        jeder E-Mail einen Link, um das Newsletter-Abonnement zu kündigen. Wenn
        der Link im Newsletter wirklich nicht zu finden ist, kontaktieren Sie
        uns bitte per Mail und wir werden Ihr Newsletter-Abo unverzüglich
        kündigen.
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Das Versenden unseres Newsletters erfolgt auf Grundlage Ihrer
        Einwilligung (Artikel 6 Abs. 1 lit. a DSGVO). Das heißt, wir dürfen
        Ihnen nur dann einen Newsletter schicken, wenn Sie sich zuvor aktiv
        dafür angemeldet haben. Gegebenenfalls können wir Ihnen auch
        Werbenachrichten senden, sofern Sie unser Kunde geworden sind und der
        Verwendung Ihrer E-Mailadresse für Direktwerbung nicht widersprochen
        haben.
      </p>
      <p>
        Informationen zu speziellen E-Mail-Marketing Diensten und wie diese
        personenbezogene Daten verarbeiten, erfahren Sie &#8211; sofern
        vorhanden &#8211; in den folgenden Abschnitten.
      </p>
      <h2 id="social-media-einleitung">Social Media Einleitung</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>Social Media Datenschutzerklärung Zusammenfassung</strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: Darstellung und Optimierung unserer
              Serviceleistung, Kontakt zu Besuchern, Interessenten u.a., Werbung
              <br />
              &#x1f4d3; Verarbeitete Daten: Daten wie etwa Telefonnummern,
              E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten,
              Informationen zu Ihrem Gerät und Ihre IP-Adresse.
              <br />
              Mehr Details dazu finden Sie beim jeweils eingesetzten
              Social-Media-Tool.
              <br />
              &#x1f4c5; Speicherdauer: abhängig von den verwendeten
              Social-Media-Plattformen
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was ist Social Media?</h3>
      <p>
        Zusätzlich zu unserer Website sind wir auch in diversen
        Social-Media-Plattformen aktiv. Dabei können Daten von Usern verarbeitet
        werden, damit wir gezielt User, die sich für uns interessieren, über die
        sozialen Netzwerke ansprechen können. Darüber hinaus können auch
        Elemente einer Social-Media-Plattform direkt in unsere Website
        eingebettet sein. Das ist etwa der Fall, wenn Sie einen sogenannten
        Social-Button auf unserer Website anklicken und direkt zu unserem
        Social-Media-Auftritt weitergeleitet werden. Als sogenannte Sozialen
        Medien oder Social Media werden Websites und Apps bezeichnet, über die
        angemeldete Mitglieder Inhalte produzieren, Inhalte offen oder in
        bestimmten Gruppen austauschen und sich mit anderen Mitgliedern
        vernetzen können.
      </p>
      <h3>Warum nutzen wir Social Media?</h3>
      <p>
        Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online
        kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten
        können wir unsere Produkte und Dienstleistungen Interessenten
        näherbringen. Die auf unserer Website eingebundenen
        Social-Media-Elemente helfen Ihnen, schnell und ohne Komplikationen zu
        unseren Social-Media-Inhalten wechseln können.
      </p>
      <p>
        Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert
        und verarbeitet werden, haben in erster Linie den Zweck, Webanalysen
        durchführen zu können. Ziel dieser Analysen ist es, genauere und
        personenbezogene Marketing- und Werbestrategien entwickeln zu können.
        Abhängig von Ihrem Verhalten auf einer Social-Media-Plattform, können
        mit Hilfe der ausgewerteten Daten, passende Rückschlüsse auf Ihre
        Interessen getroffen werden und sogenannte Userprofile erstellt werden.
        So ist es den Plattformen auch möglich, Ihnen maßgeschneiderte
        Werbeanzeigen zu präsentieren. Meistens werden für diesen Zweck Cookies
        in Ihrem Browser gesetzt, die Daten zu Ihrem Nutzungsverhalten
        speichern.
      </p>
      <p>
        Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich
        verantwortlich bleiben, auch wenn wir Dienste einer
        Social-Media-Plattform nutzen. Der Europäische Gerichtshof hat jedoch
        entschieden, dass in bestimmten Fällen der Betreiber der
        Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im
        Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir
        gesondert darauf hin und arbeiten auf Grundlage einer diesbezüglichen
        Vereinbarung. Das Wesentliche der Vereinbarung ist dann weiter unten bei
        der betroffenen Plattform wiedergegeben.
      </p>
      <p>
        Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen
        oder unserer eingebauten Elemente auch Daten von Ihnen außerhalb der
        Europäischen Union verarbeitet werden können, da viele
        Social-Media-Kanäle, beispielsweise Facebook oder Twitter, amerikanische
        Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug
        auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw.
        durchsetzen.
      </p>
      <h3>Welche Daten werden verarbeitet?</h3>
      <p>
        Welche Daten genau gespeichert und verarbeitet werden, hängt vom
        jeweiligen Anbieter der Social-Media-Plattform ab. Aber für gewöhnlich
        handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten,
        die Sie in ein Kontaktformular eingeben, Nutzerdaten wie zum Beispiel
        welche Buttons Sie klicken, wen Sie liken oder wem folgen, wann Sie
        welche Seiten besucht haben, Informationen zu Ihrem Gerät und Ihre
        IP-Adresse. Die meisten dieser Daten werden in Cookies gespeichert.
        Speziell wenn Sie selbst ein Profil bei dem besuchten Social-Media-Kanal
        haben und angemeldet sind, können Daten mit Ihrem Profil verknüpft
        werden.
      </p>
      <p>
        Alle Daten, die über eine Social-Media-Plattform erhoben werden, werden
        auch auf den Servern der Anbieter gespeichert. Somit haben auch nur die
        Anbieter Zugang zu den Daten und können Ihnen die passenden Auskünfte
        geben bzw. Änderungen vornehmen.
      </p>
      <p>
        Wenn Sie genau wissen wollen, welche Daten bei den
        Social-Media-Anbietern gespeichert und verarbeitet werden und wie sie
        der Datenverarbeitung widersprechen können, sollten Sie die jeweilige
        Datenschutzerklärung des Unternehmens sorgfältig durchlesen. Auch wenn
        Sie zur Datenspeicherung und Datenverarbeitung Fragen haben oder
        entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich
        direkt an den Anbieter wenden.
      </p>
      <h3>
        <span
          data-sheets-value='{"1":2,"2":"Wo und wie lange werden Daten gespeichert?"}'
          data-sheets-userformat='{"2":769,"3":{"1":0},"11":4,"12":0}'
        >
          Dauer der Datenverarbeitung
        </span>
      </h3>
      <p>
        Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten,
        sofern wir weitere Informationen dazu haben. Beispielsweise speichert
        die Social-Media-Plattform Facebook Daten, bis sie für den eigenen Zweck
        nicht mehr benötigt werden. Kundendaten, die mit den eigenen Userdaten
        abgeglichen werden, werden aber schon innerhalb von zwei Tagen gelöscht.
        Generell verarbeiten wir personenbezogene Daten nur so lange wie es für
        die Bereitstellung unserer Dienstleistungen und Produkte unbedingt
        notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung,
        gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch
        überschritten werden.
      </p>
      <h3>Widerspruchsrecht</h3>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies bzw. Drittanbietern wie eingebettete
        Social-Media-Elemente zu widerrufen. Das funktioniert entweder über
        unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum
        Bespiel können Sie auch die Datenerfassung durch Cookies verhindern,
        indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder
        löschen.
      </p>
      <p>
        Da bei Social-Media-Tools Cookies zum Einsatz kommen können, empfehlen
        wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um
        zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet
        werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools
        durchlesen.
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
        Social-Media-Elemente verarbeitet und gespeichert werden können, gilt
        diese Einwilligung als Rechtsgrundlage der Datenverarbeitung
        <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre
        Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres
        berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an
        einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden
        und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools
        gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die
        meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser,
        um Daten zu speichern. Darum empfehlen wir Ihnen, unseren
        Datenschutztext über Cookies genau durchzulesen und die
        Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen
        Dienstanbieters anzusehen.
      </p>
      <p>
        Informationen zu speziellen Social-Media-Plattformen erfahren Sie
        &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.
      </p>
      <h2 id="facebook-datenschutzerklaerung">Facebook Datenschutzerklärung</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>Facebook Datenschutzerklärung Zusammenfassung</strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: Optimierung unserer Serviceleistung
              <br />
              &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kundendaten, Daten
              zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre
              IP-Adresse.
              <br />
              Mehr Details dazu finden Sie weiter unten in der
              Datenschutzerklärung.
              <br />
              &#x1f4c5; Speicherdauer: bis die Daten für Facebooks Zwecke nicht
              mehr nützlich sind
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was sind Facebook-Tools?</h3>
      <p>
        Wir verwenden auf unserer Website ausgewählte Tools von Facebook.
        Facebook ist ein Social Media Network des Unternehmens Meta Platforms
        Inc. bzw. für den europäischen Raum des Unternehmens Meta Platforms
        Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
        Irland. Mithilfe dieser Tools können wir Ihnen und Menschen, die sich
        für unsere Produkte und Dienstleistungen interessieren, das bestmögliche
        Angebot bieten.
      </p>
      <p>
        Wenn über unsere eingebetteten Facebook-Elemente oder über unsere
        Facebook-Seite (Fanpage) Daten von Ihnen erhoben und weitergeleitet
        werden, sind sowohl wir als auch Facebook Irland Ltd. dafür
        verantwortlich. Für die weitere Verarbeitung dieser Daten trägt Facebook
        allein die Verantwortung. Unsere gemeinsamen Verpflichtungen wurden auch
        in einer öffentlich zugänglichen Vereinbarung unter{' '}
        <a href="https://www.facebook.com/legal/controller_addendum">
          https://www.facebook.com/legal/controller_addendum
        </a>{' '}
        verankert. Darin ist etwa festgehalten, dass wir Sie klar über den
        Einsatz der Facebook-Tools auf unserer Seite informieren müssen. Weiters
        sind wir auch dafür verantwortlich, dass die Tools datenschutzrechtlich
        sicher in unsere Website eingebunden sind. Facebook ist hingegen
        beispielsweise für die Datensicherheit der Facebook-Produkte
        verantwortlich. Bei etwaigen Fragen zur Datenerhebung und
        Datenverarbeitung durch Facebook können Sie sich direkt an das
        Unternehmen wenden. Wenn Sie die Frage an uns richten, sind wir dazu
        verpflichtet diese an Facebook weiterleiten.
      </p>
      <p>
        Im Folgenden geben wir einen Überblick über die verschiedenen Facebook
        Tools, welche Daten an Facebook gesendet werden und wie Sie diese Daten
        löschen können.
      </p>
      <p>
        Neben vielen anderen Produkten bietet Facebook auch die sogenannten
        &#8220;Facebook Business Tools&#8221; an. Das ist die offizielle
        Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben
        wir uns dafür entschieden, sie lediglich Facebook-Tools zu nennen.
        Darunter finden sich unter anderem:
      </p>
      <ul>
        <li>Facebook-Pixel</li>
        <li>
          soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)
        </li>
        <li>Facebook Login</li>
        <li>Account Kit</li>
        <li>APIs (Programmierschnittstelle)</li>
        <li>SDKs (Sammlung von Programmierwerkzeugen)</li>
        <li>Plattform-Integrationen</li>
        <li>Plugins</li>
        <li>Codes</li>
        <li>Spezifikationen</li>
        <li>Dokumentationen</li>
        <li>Technologien und Dienstleistungen</li>
      </ul>
      <p>
        Durch diese Tools erweitert Facebook Dienstleistungen und hat die
        Möglichkeit, Informationen über User-Aktivitäten außerhalb von Facebook
        zu erhalten.
      </p>
      <h3>Warum verwenden wir Facebook-Tools auf unserer Website?</h3>
      <p>
        Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die
        sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen
        (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den
        Usern passende Werbung gezeigt werden kann, benötigt Facebook allerdings
        Informationen über die Wünsche und Bedürfnisse der Menschen. So werden
        dem Unternehmen Informationen über das Userverhalten (und Kontaktdaten)
        auf unserer Webseite zur Verfügung gestellt. Dadurch sammelt Facebook
        bessere User-Daten und kann interessierten Menschen die passende Werbung
        über unsere Produkte bzw. Dienstleistungen anzeigen. Die Tools
        ermöglichen somit maßgeschneiderte Werbekampagnen auf Facebook.
      </p>
      <p>
        Daten über Ihr Verhalten auf unserer Webseite nennt Facebook
        „Event-Daten“. Diese werden auch für Messungs- und Analysedienste
        verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“ über
        die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen wir durch
        Analysen einen besseren Einblick, wie Sie unsere Dienstleistungen,
        Webseite oder Produkte verwenden. Dadurch optimieren wir mit einigen
        dieser Tools Ihre Nutzererfahrung auf unserer Webseite. Beispielsweise
        können Sie mit den sozialen Plug-ins Inhalte auf unserer Seite direkt
        auf Facebook teilen.
      </p>
      <h3>Welche Daten werden von Facebook-Tools gespeichert?</h3>
      <p>
        Durch die Nutzung einzelner Facebook-Tools können personenbezogene Daten
        (Kundendaten) an Facebook gesendet werden. Abhängig von den benutzten
        Tools können Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse
        versandt werden.
      </p>
      <p>
        Facebook verwendet diese Informationen, um die Daten mit den Daten, die
        es selbst von Ihnen hat (sofern Sie Facebook-Mitglied sind)
        abzugleichen. Bevor Kundendaten an Facebook übermittelt werden, erfolgt
        ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig großer
        Datensatz in eine Zeichenkette transformiert wird. Dies dient auch der
        Verschlüsselung von Daten.
      </p>
      <p>
        Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter
        „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf
        unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie besuchen
        oder welche Produkte Sie bei uns kaufen. Facebook teilt die erhaltenen
        Informationen nicht mit Drittanbietern (wie beispielsweise
        Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung
        oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit
        Kontaktdaten verbunden werden. Dadurch kann Facebook bessere
        personalisierte Werbung anbieten. Nach dem bereits erwähnten
        Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.
      </p>
      <p>
        Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook die
        Event-Daten nur, wenn diese mit anderen Daten (die auf andere Weise von
        Facebook erfasst wurden) zusammengefasst wurden. Diese Event-Daten nützt
        Facebook auch für Sicherheits-, Schutz-, Entwicklungs- und
        Forschungszwecke. Viele dieser Daten werden über Cookies zu Facebook
        übertragen. Cookies sind kleine Text-Dateien, die zum Speichern von
        Daten bzw. Informationen in Browsern verwendet werden. Je nach
        verwendeten Tools und abhängig davon, ob Sie Facebook-Mitglied sind,
        werden unterschiedlich viele Cookies in Ihrem Browser angelegt. In den
        Beschreibungen der einzelnen Facebook Tools gehen wir näher auf einzelne
        Facebook-Cookies ein. Allgemeine Informationen über die Verwendung von
        Facebook-Cookies erfahren Sie auch auf{' '}
        <a href="https://www.facebook.com/policies/cookies?tid=112735006">
          https://www.facebook.com/policies/cookies
        </a>{' '}
        .
      </p>
      <h3>Wie lange und wo werden die Daten gespeichert?</h3>
      <p>
        Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die
        eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat auf
        der ganzen Welt Server verteilt, wo seine Daten gespeichert werden.
        Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten
        abgeglichen wurden, innerhalb von 48 Stunden gelöscht.
      </p>
      <h3>
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
      </h3>
      <p>
        Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf
        Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.
      </p>
      <p>
        Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr
        Facebook-Konto vollständig löschen. Und so funktioniert das Löschen
        Ihres Facebook-Kontos:
      </p>
      <p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
      <p>
        2) Anschließend klicken Sie in der linken Spalte auf „Deine
        Facebook-Informationen“.
      </p>
      <p>3) Nun klicken Sie &#8220;Deaktivierung und Löschung&#8221;.</p>
      <p>
        4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter und
        Konto löschen“
      </p>
      <p>
        5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann auf
        „Konto löschen“
      </p>
      <p>
        Die Speicherung der Daten, die Facebook über unsere Seite erhält,
        erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In Ihrem
        Browser können Sie einzelne oder alle Cookies deaktivieren, löschen oder
        verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies
        auf unterschiedliche Art und Weise. Unter dem Abschnitt „Cookies“ finden
        Sie die entsprechenden Links zu den jeweiligen Anleitungen der
        bekanntesten Browser.
      </p>
      <p>
        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
        Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
        gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
        entscheiden, ob Sie es erlauben oder nicht.
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
        Facebook-Tools verarbeitet und gespeichert werden können, gilt diese
        Einwilligung als Rechtsgrundlage der Datenverarbeitung
        <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre
        Daten auch auf Grundlage unseres berechtigten Interesses
        <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und
        guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern
        gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein,
        soweit Sie eine Einwilligung erteilt haben. Die meisten
        Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten
        zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über
        Cookies genau durchzulesen und die Datenschutzerklärung oder die
        Cookie-Richtlinien von Facebook anzusehen.
      </p>
      <p>
        Facebook verarbeitet Daten von Ihnen u.a. auch in den USA. Facebook bzw.
        Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks,
        wodurch der korrekte und sichere Datentransfer personenbezogener Daten
        von EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden
        Sie auf{' '}
        <a
          href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
          target="_blank"
          rel="follow noopener"
        >
          https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en
        </a>{' '}
        .
      </p>
      <p>
        Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46.
        Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual
        Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen
        und sollen sicherstellen, dass Ihre Daten auch dann den europäischen
        Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden.
        Durch das EU-US Data Privacy Framework und durch die
        Standardvertragsklauseln verpflichtet sich Facebook, bei der
        Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau
        einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet
        und verwaltet werden. Diese Klauseln basieren auf einem
        Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und
        die entsprechenden Standardvertragsklauseln u.a. hier:{' '}
        <a
          href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
          target="_blank"
          rel="follow noopener"
        >
          https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
        </a>{' '}
      </p>
      <p>
        Die Facebook Datenverarbeitungsbedingung, welche auf die
        Standardvertragsklauseln verweisen, finden Sie unter{' '}
        <a href="https://www.facebook.com/legal/terms/dataprocessing">
          https://www.facebook.com/legal/terms/dataprocessing
        </a>{' '}
        .
      </p>
      <p>
        Wir hoffen, wir haben Ihnen die wichtigsten Informationen über die
        Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht.
        Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten
        verwendet, empfehlen wir Ihnen die Datenrichtlinien auf{' '}
        <a href="https://www.facebook.com/privacy/policy/?tid=112735006">
          https://www.facebook.com/privacy/policy/
        </a>{' '}
        .
      </p>
      <h2 id="facebook-soziale-plug-ins-datenschutzerklaerung">
        Facebook Soziale Plug-ins Datenschutzerklärung
      </h2>
      <p>
        Auf unserer Website sind sogenannte soziale Plug-ins des Unternehmens
        Meta Platforms Inc. eingebaut. Sie erkennen diese Buttons am klassischen
        Facebook-Logo, wie dem „Gefällt mir“-Button (die Hand mit erhobenem
        Daumen) oder an einer eindeutigen „Facebook Plug-in“-Kennzeichnung. Ein
        soziales Plug-in ist ein kleiner Teil von Facebook, der in unsere Seite
        integriert ist. Jedes Plug-in hat eine eigene Funktion. Die am meisten
        verwendeten Funktionen sind die bekannten &#8220;Gefällt mir&#8221;- und
        &#8220;Teilen&#8221;-Buttons.
      </p>
      <p>Folgende soziale Plug-ins werden von Facebook angeboten:</p>
      <ul>
        <li>&#8220;Speichern&#8221;-Button</li>
        <li>&#8220;Gefällt mir&#8221;-Button, Teilen, Senden und Zitat</li>
        <li>Seiten-Plug-in</li>
        <li>Kommentare</li>
        <li>Messenger-Plug-in</li>
        <li>Eingebettete Beiträge und Videoplayer</li>
        <li>Gruppen-Plug-in</li>
      </ul>
      <p>
        Auf{' '}
        <a href="https://developers.facebook.com/docs/plugins">
          https://developers.facebook.com/docs/plugins
        </a>{' '}
         erhalten Sie nähere Informationen, wie die einzelnen Plug-ins verwendet
        werden. Wir nützen die sozialen Plug-ins einerseits, um Ihnen ein
        besseres Usererlebnis auf unserer Seite zu bieten, andererseits weil
        Facebook dadurch unsere Werbeanzeigen optimieren kann.
      </p>
      <p>
        Sofern Sie ein Facebook-Konto haben oder{' '}
        <a href="https://www.facebook.com/">https://www.facebook.com/</a>  schon
        mal besucht haben, hat Facebook bereits mindestens ein Cookie in Ihrem
        Browser gesetzt. In diesem Fall sendet Ihr Browser über dieses Cookie
        Informationen an Facebook, sobald Sie unsere Seite besuchen bzw. mit
        sozialen Plug-ins (z.B. dem „Gefällt mir“-Button) interagieren.
      </p>
      <p>
        Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder
        gelöscht bzw. anonymisiert. Laut Facebook gehören zu diesen Daten Ihre
        IP-Adresse, welche Webseite Sie besucht haben, das Datum, die Uhrzeit
        und weitere Informationen, die Ihren Browser betreffen.
      </p>
      <p>
        Um zu verhindern, dass Facebook während Ihres Besuches auf unserer
        Webseite viele Daten sammelt und mit den Facebook-Daten verbindet,
        müssen Sie sich während des Webseitenbesuchs von Facebook abmelden
        (ausloggen).
      </p>
      <p>
        Falls Sie bei Facebook nicht angemeldet sind oder kein Facebook-Konto
        besitzen, sendet Ihr Browser weniger Informationen an Facebook, weil Sie
        weniger Facebook-Cookies haben. Dennoch können Daten wie beispielsweise
        Ihre IP-Adresse oder welche Webseite Sie besuchen an Facebook übertragen
        werden. Wir möchten noch ausdrücklich darauf hinweisen, dass wir über
        die genauen Inhalte der Daten nicht exakt Bescheid wissen. Wir versuchen
        aber Sie nach unserem aktuellen Kenntnisstand so gut als möglich über
        die Datenverarbeitung aufzuklären. Wie Facebook die Daten nutzt, können
        Sie auch in den Datenrichtline des Unternehmens unter{' '}
        <a href="https://www.facebook.com/about/privacy/update">
          https://www.facebook.com/about/privacy/update
        </a>{' '}
         nachlesen.
      </p>
      <p>
        Folgende Cookies werden in Ihrem Browser mindestens gesetzt, wenn Sie
        eine Webseite mit sozialen Plug-ins von Facebook besuchen:
      </p>
      <p>
        <strong>Name:</strong> dpr
        <br />
        <strong>Wert:</strong> keine Angabe
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit
        die sozialen Plug-ins auf unserer Webseite funktionieren.
        <br />
        <strong>Ablaufdatum:</strong> nach Sitzungsende
      </p>
      <p>
        <strong>Name:</strong> fr
        <br />
        <strong>Wert:</strong>
        0jieyh4112735006c2GnlufEJ9..Bde09j&#8230;1.0.Bde09j
        <br />
        <strong>Verwendungszweck:</strong> Auch das Cookie ist nötig, dass die
        Plug-ins einwandfrei funktionieren.
        <br />
        <strong>Ablaufdatum::</strong> nach 3 Monaten
      </p>
      <p>
        <strong>Anmerkung:</strong> Diese Cookies wurden nach einem Test
        gesetzt, auch wenn Sie nicht Facebook-Mitglied sind.
      </p>
      <p>
        Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
        für Werbeanzeigen unter{' '}
        <a href="https://www.facebook.com/adpreferences/advertisers/">
          https://www.facebook.com/adpreferences/advertisers/
        </a>{' '}
        selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf{' '}
        <a
          href="https://www.youronlinechoices.com/de/praferenzmanagement/?tid=112735006"
          target="_blank"
          rel="follow noopener"
        >
          https://www.youronlinechoices.com/de/praferenzmanagement/?tid=112735006
        </a>{' '}
        grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort haben
        Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.
      </p>
      <p>
        Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
        empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf{' '}
        <a
          href="https://www.facebook.com/privacy/policy/?tid=112735006"
          target="_blank"
          rel="follow noopener"
        >
          https://www.facebook.com/privacy/policy/
        </a>{' '}
        .
      </p>
      <h2 id="audio-video-einleitung">Audio &amp; Video Einleitung</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>
                Audio &amp; Video Datenschutzerklärung Zusammenfassung
              </strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: Optimierung unserer Serviceleistung
              <br />
              &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten
              zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre
              IP-Adresse können gespeichert werden.
              <br />
              Mehr Details dazu finden Sie weiter unten in den entsprechenden
              Datenschutztexten.
              <br />
              &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert,
              solange sie für den Dienstzweck nötig sind
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was sind Audio- und Videoelemente?</h3>
      <p>
        Wir haben auf unsere Website Audio- bzw. Videoelemente eingebunden,
        damit Sie sich direkt über unsere Website etwa Videos ansehen oder
        Musik/Podcasts anhören können. Die Inhalte werden von Dienstanbietern
        zur Verfügung gestellt. Alle Inhalte werden also auch von den
        entsprechenden Servern der Anbieter bezogen.
      </p>
      <p>
        Es handelt sich dabei um eingebundene Funktionselemente von Plattformen
        wie etwa YouTube, Vimeo oder Spotify. Die Nutzung dieser Portale ist in
        der Regel kostenlos, es können aber auch kostenpflichtige Inhalte
        veröffentlicht werden. Mit Hilfe dieser eingebundenen Elemente könne Sie
        sich über unsere Website die jeweiligen Inhalte anhören oder ansehen.
      </p>
      <p>
        Wenn Sie Audio- oder Videoelemente auf unsere Website verwenden, können
        auch personenbezogene Daten von Ihnen an die Dienstanbieter übermittelt,
        verarbeitet und gespeichert werden.
      </p>
      <h3>
        Warum verwenden wir Audio- &amp; Videoelemente auf unserer Website?
      </h3>
      <p>
        Natürlich wollen wir Ihnen auf unserer Website das beste Angebot
        liefern. Und uns ist bewusst, dass Inhalte nicht mehr bloß in Text und
        statischem Bild vermittelt werden. Statt Ihnen einfach nur einen Link zu
        einem Video zu geben, bieten wir Ihnen direkt auf unserer Website Audio-
        und Videoformate, die unterhaltend oder informativ und im Idealfall
        sogar beides sind. Das erweitert unser Service und erleichtert Ihnen den
        Zugang zu interessanten Inhalten. Somit bieten wir neben unseren Texten
        und Bildern auch Video und/oder Audio-Inhalte an.
      </p>
      <h3>Welche Daten werden durch Audio- &amp; Videoelemente gespeichert?</h3>
      <p>
        Wenn Sie eine Seite auf unserer Website aufrufen, die beispielsweise ein
        eingebettetes Video hat, verbindet sich Ihr Server mit dem Server des
        Dienstanbieters. Dabei werden auch Daten von Ihnen an den Drittanbieter
        übertragen und dort gespeichert. Manche Daten werden ganz unabhängig
        davon, ob Sie bei dem Drittanbieter ein Konto haben oder nicht,
        gesammelt und gespeichert. Dazu zählen meist Ihre IP-Adresse,
        Browsertyp, Betriebssystem, und weitere allgemeine Informationen zu
        Ihrem Endgerät. Weiters werden von den meisten Anbietern auch
        Informationen über Ihre Webaktivität eingeholt. Dazu zählen etwa
        Sitzungsdauer, Absprungrate, auf welchen Button Sie geklickt haben oder
        über welche Website Sie den Dienst nutzen. All diese Informationen
        werden meist über Cookies oder Pixel-Tags (auch Web Beacon genannt)
        gespeichert. Pseudonymisierte Daten werden meist in Cookies in Ihrem
        Browser gespeichert. Welche Daten genau gespeichert und verarbeitet
        werden, erfahren Sie stets in der Datenschutzerklärung des jeweiligen
        Anbieters.
      </p>
      <h3>Dauer der Datenverarbeitung</h3>
      <p>
        Wie lange die Daten auf den Servern der Drittanbieter genau gespeichert
        werden, erfahren Sie entweder weiter unten im Datenschutztext des
        jeweiligen Tools oder in der Datenschutzerklärung des Anbieters.
        Grundsätzlich werden personenbezogene Daten immer nur so lange
        verarbeitet, wie es für die Bereitstellung unserer Dienstleistungen oder
        Produkte unbedingt nötig wird. Dies gilt in der Regel auch für
        Drittanbieter. Meist können Sie davon ausgehen, dass gewisse Daten über
        mehrere Jahre auf den Servern der Drittanbieter gespeichert werden.
        Daten können speziell in Cookies unterschiedlich lange gespeichert
        werden. Manche Cookies werden bereits nach dem Verlassen der Website
        wieder gelöscht, anderen können über einige Jahre in Ihrem Browser
        gespeichert sein.
      </p>
      <h3>Widerspruchsrecht</h3>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das
        funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch
        Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten,
        deaktivieren oder löschen. Die Rechtmäßigkeit der Verarbeitung bis zum
        Widerruf bleibt unberührt.
      </p>
      <p>
        Da durch die eingebundenen Audio- und Video-Funktionen auf unserer Seite
        meist auch Cookies verwendet werden, sollte Sie sich auch unsere
        allgemeine Datenschutzerklärung über Cookies durchlesen. In den
        Datenschutzerklärungen der jeweiligen Drittanbieter erfahren Sie
        genaueres über den Umgang und die Speicherung Ihrer Daten.
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
        Audio- und Video-Elemente verarbeitet und gespeichert werden können,
        gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung
        <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre
        Daten auch auf Grundlage unseres berechtigten Interesses
        <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und
        guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern
        gespeichert und verarbeitet. Wir setzen die eingebundenen Audio- und
        Video-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt
        haben.
      </p>
      <h2 id="youtube-datenschutzerklaerung">YouTube Datenschutzerklärung</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>YouTube Datenschutzerklärung Zusammenfassung</strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: Optimierung unserer Serviceleistung
              <br />
              &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten
              zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre
              IP-Adresse können gespeichert werden.
              <br />
              Mehr Details dazu finden Sie weiter unten in dieser
              Datenschutzerklärung.
              <br />
              &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert,
              solange sie für den Dienstzweck nötig sind
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was ist YouTube?</h3>
      <p>
        Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir
        Ihnen interessante Videos direkt auf unserer Seite präsentieren. YouTube
        ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist.
        Betrieben wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San
        Bruno, CA 94066, USA. Wenn Sie auf unserer Website eine Seite aufrufen,
        die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser
        automatisch mit den Servern von YouTube bzw. Google. Dabei werden (je
        nach Einstellungen) verschiedene Daten übertragen. Für die gesamte
        Datenverarbeitung im europäischen Raum ist Google Ireland Limited
        (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich.
      </p>
      <p>
        Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet
        werden, warum wir YouTube-Videos eingebunden haben und wie Sie Ihre
        Daten verwalten oder löschen können.
      </p>
      <p>
        Auf YouTube können die User kostenlos Videos ansehen, bewerten,
        kommentieren und selbst hochladen. Über die letzten Jahre wurde YouTube
        zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos
        auf unserer Webseite anzeigen können, stellt YouTube einen
        Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut haben.
      </p>
      <h3>Warum verwenden wir YouTube-Videos auf unserer Website?</h3>
      <p>
        YouTube ist die Videoplattform mit den meisten Besuchern und dem besten
        Content. Wir sind bemüht, Ihnen die bestmögliche User-Erfahrung auf
        unserer Webseite zu bieten. Und natürlich dürfen interessante Videos
        dabei nicht fehlen. Mithilfe unserer eingebetteten Videos stellen wir
        Ihnen neben unseren Texten und Bildern weiteren hilfreichen Content zur
        Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch
        die eingebetteten Videos leichter gefunden. Auch wenn wir über Google
        Ads Werbeanzeigen schalten, kann Google – dank der gesammelten Daten –
        diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere
        Angebote interessieren.
      </p>
      <h3>Welche Daten werden von YouTube gespeichert?</h3>
      <p>
        Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut
        hat, setzt YouTube zumindest ein Cookie, das Ihre IP-Adresse und unsere
        URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann
        YouTube Ihre Interaktionen auf unserer Webseite meist mithilfe von
        Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie Sitzungsdauer,
        Absprungrate, ungefährer Standort, technische Informationen wie
        Browsertyp, Bildschirmauflösung oder Ihr Internetanbieter. Weitere Daten
        können Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten über
        Social Media oder das Hinzufügen zu Ihren Favoriten auf YouTube sein.
      </p>
      <p>
        Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet
        sind, speichert Google Daten mit einer eindeutigen Kennung, die mit
        Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise
        Ihre bevorzugte Spracheinstellung beibehalten. Aber viele
        Interaktionsdaten können nicht gespeichert werden, da weniger Cookies
        gesetzt werden.
      </p>
      <p>
        In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser
        gesetzt wurden. Wir zeigen einerseits Cookies, die ohne angemeldetes
        YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit
        angemeldetem Account gesetzt werden. Die Liste kann keinen
        Vollständigkeitsanspruch erheben, weil die Userdaten immer von den
        Interaktionen auf YouTube abhängen.
      </p>
      <p>
        <strong>Name:</strong> YSC
        <br />
        <strong>Wert:</strong> b9-CV6ojI5Y112735006-1
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie registriert eine
        eindeutige ID, um Statistiken des gesehenen Videos zu speichern.
        <br />
        <strong>Ablaufdatum:</strong> nach Sitzungsende
      </p>
      <p>
        <strong>Name:</strong> PREF
        <br />
        <strong>Wert:</strong> f1=50000000
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls
        Ihre eindeutige ID. Google bekommt über PREF Statistiken, wie Sie
        YouTube-Videos auf unserer Webseite verwenden.
        <br />
        <strong>Ablaufdatum:</strong> nach 8 Monaten
      </p>
      <p>
        <strong>Name:</strong> GPS
        <br />
        <strong>Wert:</strong> 1<br />
        <strong>Verwendungszweck:</strong> Dieses Cookie registriert Ihre
        eindeutige ID auf mobilen Geräten, um den GPS-Standort zu tracken.
        <br />
        <strong>Ablaufdatum:</strong> nach 30 Minuten
      </p>
      <p>
        <strong>Name:</strong> VISITOR_INFO1_LIVE
        <br />
        <strong>Wert:</strong> 95Chz8bagyU
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite
        des Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu
        schätzen.
        <br />
        <strong>Ablaufdatum:</strong> nach 8 Monaten
      </p>
      <p>
        Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto
        angemeldet sind:
      </p>
      <p>
        <strong>Name:</strong> APISID
        <br />
        <strong>Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7112735006-
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein
        Profil über Ihre Interessen zu erstellen. Genützt werden die Daten für
        personalisierte Werbeanzeigen.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> CONSENT
        <br />
        <strong>Wert:</strong> YES+AT.de+20150628-20-0
        <br />
        <strong>Verwendungszweck:</strong> Das Cookie speichert den Status der
        Zustimmung eines Users zur Nutzung unterschiedlicher Services von
        Google. CONSENT dient auch der Sicherheit, um User zu überprüfen und
        Userdaten vor unbefugten Angriffen zu schützen.
        <br />
        <strong>Ablaufdatum: </strong>nach 19 Jahren
      </p>
      <p>
        <strong>Name:</strong> HSID
        <br />
        <strong>Wert:</strong> AcRwpgUik9Dveht0I
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein
        Profil über Ihre Interessen zu erstellen. Diese Daten helfen
        personalisierte Werbung anzeigen zu können.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> LOGIN_INFO
        <br />
        <strong>Wert:</strong> AFmmF2swRQIhALl6aL…
        <br />
        <strong>Verwendungszweck:</strong> In diesem Cookie werden Informationen
        über Ihre Login-Daten gespeichert.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> SAPISID
        <br />
        <strong>Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es
        Ihren Browser und Ihr Gerät eindeutig identifiziert. Es wird verwendet,
        um ein Profil über Ihre Interessen zu erstellen.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> SID
        <br />
        <strong>Wert:</strong> oQfNKjAsI112735006-
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie speichert Ihre
        Google-Konto-ID und Ihren letzten Anmeldezeitpunkt in digital signierter
        und verschlüsselter Form.
        <br />
        <strong>Ablaufdatum:</strong> nach 2 Jahren
      </p>
      <p>
        <strong>Name:</strong> SIDCC
        <br />
        <strong>Wert:</strong> AN0-TYuqub2JOcDTyL
        <br />
        <strong>Verwendungszweck:</strong> Dieses Cookie speichert
        Informationen, wie Sie die Webseite nutzen und welche Werbung Sie vor
        dem Besuch auf unserer Seite möglicherweise gesehen haben.
        <br />
        <strong>Ablaufdatum:</strong> nach 3 Monaten
      </p>
      <h3>Wie lange und wo werden die Daten gespeichert?</h3>
      <p>
        Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den
        Google-Servern gespeichert. Die meisten dieser Server befinden sich in
        Amerika. Unter{' '}
        <a
          href="https://www.google.com/about/datacenters/locations/?hl=de"
          target="_blank"
          rel="follow noopener"
        >
          https://www.google.com/about/datacenters/locations/?hl=de
        </a>{' '}
        sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten
        sind auf den Servern verteilt. So sind die Daten schneller abrufbar und
        vor Manipulation besser geschützt.
      </p>
      <p>
        Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten
        können Sie jederzeit löschen, andere werden automatisch nach einer
        begrenzten Zeit gelöscht und wieder andere werden von Google über
        längere Zeit gespeichert. Einige Daten (wie Elemente aus „Meine
        Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto
        gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen.
        Auch wenn Sie nicht in einem Google-Konto angemeldet sind, können Sie
        einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
        löschen.
      </p>
      <h3>
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
      </h3>
      <p>
        Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der
        2019 eingeführten automatischen Löschfunktion von Standort- und
        Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung
        &#8211; entweder 3 oder 18 Monate gespeichert und dann gelöscht.
      </p>
      <p>
        Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren
        Browser so konfigurieren, dass Cookies von Google gelöscht bzw.
        deaktiviert werden. Je nachdem welchen Browser Sie verwenden,
        funktioniert dies auf unterschiedliche Art und Weise. Unter dem
        Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den
        jeweiligen Anleitungen der bekanntesten Browser.
      </p>
      <p>
        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
        Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
        gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
        entscheiden, ob Sie es erlauben oder nicht.
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
        YouTube-Elemente verarbeitet und gespeichert werden können, gilt diese
        Einwilligung als Rechtsgrundlage der Datenverarbeitung
        <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre
        Daten auch auf Grundlage unseres berechtigten Interesses
        <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und
        guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern
        gespeichert und verarbeitet. Wir setzen die eingebundenen
        YouTube-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung
        erteilt haben. YouTube setzt auch Cookies in Ihrem Browser, um Daten zu
        speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über
        Cookies genau durchzulesen und die Datenschutzerklärung oder die
        Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.
      </p>
      <p>
        Youtube verarbeitet Daten von Ihnen u.a. auch in den USA. Youtube bzw.
        Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch
        der korrekte und sichere Datentransfer personenbezogener Daten von
        EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie
        auf{' '}
        <a
          href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
          target="_blank"
          rel="follow noopener"
        >
          https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en
        </a>{' '}
        .
      </p>
      <p>
        Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46.
        Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual
        Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen
        und sollen sicherstellen, dass Ihre Daten auch dann den europäischen
        Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden.
        Durch das EU-US Data Privacy Framework und durch die
        Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung
        Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten,
        selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet
        werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der
        EU-Kommission. Sie finden den Beschluss und die entsprechenden
        Standardvertragsklauseln u.a. hier:{' '}
        <a
          href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
          target="_blank"
          rel="follow noopener"
        >
          https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
        </a>{' '}
      </p>
      <p>
        Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing
        Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie
        unter{' '}
        <a
          href="https://business.safety.google/intl/de/adsprocessorterms/"
          target="_blank"
          rel="follow noopener"
        >
          https://business.safety.google/intl/de/adsprocessorterms/
        </a>{' '}
        .
      </p>
      <p>
        Da YouTube ein Tochterunternehmen von Google ist, gibt es eine
        gemeinsame Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren
        Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung
        unter{' '}
        <a
          href="https://policies.google.com/privacy?hl=de?tid=112735006"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de.
        </a>{' '}
      </p>
      <h2 id="webdesign-einleitung">Webdesign Einleitung</h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>Webdesign Datenschutzerklärung Zusammenfassung</strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: Verbesserung der Nutzererfahrung
              <br />
              &#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden,
              hängt stark von den verwendeten Diensten ab. Meist handelt es sich
              etwa um IP-Adresse, technische Daten, Spracheinstellungen, 
              Browserversion, Bildschirmauflösung und Name des Browsers. Mehr
              Details dazu finden Sie bei den jeweils eingesetzten
              Webdesign-Tools.
              <br />
              &#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was ist Webdesign?</h3>
      <p>
        Wir verwenden auf unserer Website verschiedene Tools, die unserem
        Webdesign dienen. Bei Webdesign geht es nicht, wie oft angenommen, nur
        darum, dass unsere Website hübsch aussieht, sondern auch um
        Funktionalität und Leistung. Aber natürlich ist die passende Optik einer
        Website auch eines der großen Ziele professionellen Webdesigns.
        Webdesign ist ein Teilbereich des Mediendesigns und beschäftigt sich
        sowohl mit der visuellen als auch der strukturellen und funktionalen
        Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign Ihre
        Erfahrung auf unserer Website zu verbessern. Im Webdesign-Jargon spricht
        man in diesem Zusammenhang von User-Experience (UX) und Usability. Unter
        User Experience versteht man alle Eindrücke und Erlebnisse, die der
        Websitebesucher auf einer Website erfährt. Ein Unterpunkt der User
        Experience ist die Usability. Dabei geht es um die Nutzerfreundlichkeit
        einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte,
        Unterseiten oder Produkte klar strukturiert sind und Sie leicht und
        schnell finden, wonach Sie suchen. Um Ihnen die bestmögliche Erfahrung
        auf unserer Website zu bieten, verwenden wir auch sogenannte
        Webdesign-Tools von Drittanbietern. Unter die Kategorie „Webdesign“
        fallen in dieser Datenschutzerklärung also alle Dienste, die unsere
        Website gestalterisch verbessern. Das können beispielsweise
        Schriftarten, diverse Plugins oder andere eingebundene
        Webdesign-Funktionen sein.
      </p>
      <h3>Warum verwenden wir Webdesign-Tools?</h3>
      <p>
        Wie Sie Informationen auf einer Website aufnehmen, hängt sehr stark von
        der Struktur, der Funktionalität und der visuellen Wahrnehmung der
        Website ab. Daher wurde auch für uns ein gutes und professionelles
        Webdesign immer wichtiger. Wir arbeiten ständig an der Verbesserung
        unserer Website und sehen dies auch als erweiterte Dienstleistung für
        Sie als Websitebesucher. Weiters hat eine schöne und funktionierende
        Website auch wirtschaftliche Vorteile für uns. Schließlich werden Sie
        uns nur besuchen und unsere Angebote in Anspruch nehmen, wenn Sie sich
        rundum wohl fühlen.
      </p>
      <h3>Welche Daten werden durch Webdesign-Tools gespeichert?</h3>
      <p>
        Wenn Sie unsere Website besuchen, können Webdesign-Elemente in unseren
        Seiten eingebunden sein, die auch Daten verarbeiten können. Um welche
        Daten es sich genau handelt, hängt natürlich stark von den verwendeten
        Tools ab. Weiter unter sehen Sie genau, welche Tools wir für unsere
        Website verwenden. Wir empfehlen Ihnen für nähere Informationen über die
        Datenverarbeitung auch die jeweilige Datenschutzerklärung der
        verwendeten Tools durchzulesen. Meistens erfahren Sie dort, welche Daten
        verarbeitet werden, ob Cookies eingesetzt werden und wie lange die Daten
        aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden
        beispielsweise auch Informationen wie Spracheinstellungen, IP-Adresse,
        Version des Browsers, Bildschirmauflösung des Browsers und Name des
        Browsers automatisch an die Google-Server übertragen.
      </p>
      <h3>Dauer der Datenverarbeitung</h3>
      <p>
        Wie lange Daten verarbeitet werden, ist sehr individuell und hängt von
        den eingesetzten Webdesign-Elementen ab. Wenn Cookies beispielsweise zum
        Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch
        ein paar Jahre dauern. Machen Sie sich diesbezüglich bitte schlau. Dazu
        empfehlen wir Ihnen einerseits unseren allgemeinen Textabschnitt über
        Cookies sowie die Datenschutzerklärungen der eingesetzten Tools. Dort
        erfahren Sie in der Regel, welche Cookies genau eingesetzt werden, und
        welche Informationen darin gespeichert werden. Google-Font-Dateien
        werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer
        Website verbessert werden. Grundsätzlich werden Daten immer nur so lange
        aufbewahrt, wie es für die Bereitstellung des Dienstes nötig ist. Bei
        gesetzlichen Vorschreibungen können Daten auch länger gespeichert
        werden.
      </p>
      <h3>Widerspruchsrecht</h3>
      <p>
        Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung
        zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das
        funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Sie können auch die Datenerfassung durch Cookies
        verhindern, indem Sie in Ihrem Browser die Cookies verwalten,
        deaktivieren oder löschen. Unter Webdesign-Elementen (meistens bei
        Schriftarten) gibt es allerdings auch Daten, die nicht ganz so einfach
        gelöscht werden können. Das ist dann der Fall, wenn Daten direkt bei
        einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie
        z. B. Google) übermittelt werden. Wenden Sie sich dann bitte an den
        Support des entsprechenden Anbieters. Im Fall von Google erreichen Sie
        den Support unter{' '}
        <a href="https://support.google.com/?hl=de">
          https://support.google.com/?hl=de
        </a>{' '}
        .
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Wenn Sie eingewilligt haben, dass Webdesign-Tools eingesetzt werden
        dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung
        diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a
        DSGVO (Einwilligung) die Rechtsgrundlage für die Verarbeitung
        personenbezogener Daten, wie sie bei der Erfassung durch Webdesign-Tools
        vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes
        Interesse, das Webdesign auf unserer Website zu verbessern. Schließlich
        können wir Ihnen nur dann ein schönes und professionelles Webangebot
        liefern. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit.
        f DSGVO (Berechtigte Interessen). Wir setzen Webdesign-Tools gleichwohl
        nur ein, soweit Sie eine Einwilligung erteilt haben. Das wollen wir hier
        auf jeden Fall nochmals betonen.
      </p>
      <p>
        Informationen zu speziellen Webdesign-Tools erhalten Sie &#8211; sofern
        vorhanden &#8211; in den folgenden Abschnitten.
      </p>
      <h2 id="google-fonts-datenschutzerklaerung">
        Google Fonts Datenschutzerklärung
      </h2>
      <table border="1" cellpadding="15">
        <tbody>
          <tr>
            <td>
              <strong>Google Fonts Datenschutzerklärung Zusammenfassung</strong>
              <br />
              &#x1f465; Betroffene: Besucher der Website
              <br />
              &#x1f91d; Zweck: Optimierung unserer Serviceleistung
              <br />
              &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse und CSS-
              und Schrift-Anfragen
              <br />
              Mehr Details dazu finden Sie weiter unten in dieser
              Datenschutzerklärung.
              <br />
              &#x1f4c5; Speicherdauer: Font-Dateien werden bei Google ein Jahr
              gespeichert
              <br />
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
              (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
              Interessen)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Was sind Google Fonts?</h3>
      <p>
        Auf unserer Website verwenden wir Google Fonts. Das sind die
        &#8220;Google-Schriften&#8221; der Firma Google Inc. Für den
        europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon
        House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
        verantwortlich.
      </p>
      <p>
        Für die Verwendung von Google-Schriftarten müssen Sie sich nicht
        anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine
        Cookies in Ihrem Browser gespeichert. Die Dateien (CSS,
        Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com
        und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach
        CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten.
        Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass
        Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an
        Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading
        Style Sheets) und der verwendeten Schriftarten und speichert diese Daten
        sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im
        Detail ansehen.
      </p>
      <p>
        Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800
        Schriftarten, die{' '}
        <a href="https://de.wikipedia.org/wiki/Google_LLC?tid=112735006">
          Google
        </a>{' '}
         Ihren Nutzern kostenlos zu Verfügung stellen.
      </p>
      <p>
        Viele dieser Schriftarten sind unter der SIL Open Font
        License veröffentlicht, während andere unter
        der Apache-Lizenz veröffentlicht wurden. Beides sind freie
        Software-Lizenzen.
      </p>
      <h3>Warum verwenden wir Google Fonts auf unserer Website?</h3>
      <p>
        Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen,
        und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts
        ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu
        halten. Alle Google-Schriften sind automatisch für das Web optimiert und
        dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen
        Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die
        niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google
        Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme
        (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen
        Endgeräten können zu Fehlern führen. Solche Fehler können teilweise
        Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content
        Delivery Network (CDN) gibt es mit Google Fonts keine
        plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen
        Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und
        funktioniert zuverlässig auf den meisten modernen mobilen
        Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone,
        iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser
        gesamtes Online-Service so schön und einheitlich wie möglich darstellen
        können.
      </p>
      <h3>Welche Daten werden von Google gespeichert?</h3>
      <p>
        Wenn Sie unsere Webseite besuchen, werden die Schriften über einen
        Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an
        die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw.
        Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde
        entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten
        auf das zu reduzieren, was für eine ordentliche Bereitstellung von
        Schriften nötig ist. API steht übrigens für „Application Programming
        Interface“ und dient unter anderem als Datenübermittler im
        Softwarebereich.
      </p>
      <p>
        Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und
        ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google
        feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
        veröffentlicht Google auf internen Analyseseiten, wie beispielsweise
        Google Analytics. Zudem verwendet Google auch Daten des eigenen
        Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften
        verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts
        veröffentlicht. Unternehmer und Entwickler nützen das
        Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen
        zu können.
      </p>
      <p>
        Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage
        auch Informationen wie Spracheinstellungen, IP-Adresse, Version des
        Browsers, Bildschirmauflösung des Browsers und Name des Browsers
        automatisch an die Google-Server übertragen werden. Ob diese Daten auch
        gespeichert werden, ist nicht klar feststellbar bzw. wird von Google
        nicht eindeutig kommuniziert.
      </p>
      <h3>Wie lange und wo werden die Daten gespeichert?</h3>
      <p>
        Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen
        Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das
        ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu
        nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und
        schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
      </p>
      <p>
        Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt
        damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern.
        Wenn Millionen von Webseiten auf die gleichen Schriften verweisen,
        werden sie nach dem ersten Besuch zwischengespeichert und erscheinen
        sofort auf allen anderen später besuchten Webseiten wieder. Manchmal
        aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die
        Abdeckung von Sprache zu erhöhen und das Design zu verbessern.
      </p>
      <h3>
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
      </h3>
      <p>
        Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können
        nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf
        automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu
        können, müssen Sie den Google-Support auf{' '}
        <a href="https://support.google.com/?hl=de&amp;tid=112735006">
          https://support.google.com/?hl=de&amp;tid=112735006
        </a>{' '}
        kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn
        Sie unsere Seite nicht besuchen.
      </p>
      <p>
        Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten
        Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer
        an Schriftarten zugreifen und so das Optimum für unsere Webseite
        rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf{' '}
        <a href="https://developers.google.com/fonts/faq?tid=112735006">
          https://developers.google.com/fonts/faq?tid=112735006
        </a>{' '}
        . Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein,
        doch wirklich detaillierte Informationen über Datenspeicherung sind
        nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
        Informationen über gespeicherten Daten zu bekommen.
      </p>
      <h3>Rechtsgrundlage</h3>
      <p>
        Wenn Sie eingewilligt haben, dass Google Fonts eingesetzt werden darf,
        ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese
        Einwilligung. Diese Einwilligung stellt laut
        <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong>
        die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie
        sie bei der Erfassung durch Google Fonts vorkommen kann, dar.
      </p>
      <p>
        Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Font
        zu verwenden, um unser Online-Service zu optimieren. Die dafür
        entsprechende Rechtsgrundlage ist
        <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.
        Wir setzen Google Font gleichwohl nur ein, soweit Sie eine Einwilligung
        erteilt haben.
      </p>
      <p>
        Google verarbeitet Daten von Ihnen u.a. auch in den USA. Google ist
        aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der
        korrekte und sichere Datentransfer personenbezogener Daten von
        EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie
        auf{' '}
        <a
          href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
          target="_blank"
          rel="follow noopener"
        >
          https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en
        </a>{' '}
        .
      </p>
      <p>
        Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46.
        Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual
        Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen
        und sollen sicherstellen, dass Ihre Daten auch dann den europäischen
        Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden.
        Durch das EU-US Data Privacy Framework und durch die
        Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung
        Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten,
        selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet
        werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der
        EU-Kommission. Sie finden den Beschluss und die entsprechenden
        Standardvertragsklauseln u.a. hier:{' '}
        <a
          href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
          target="_blank"
          rel="follow noopener"
        >
          https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
        </a>{' '}
      </p>
      <p>
        Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing
        Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie
        unter{' '}
        <a
          href="https://business.safety.google/intl/de/adsprocessorterms/"
          target="_blank"
          rel="follow noopener"
        >
          https://business.safety.google/intl/de/adsprocessorterms/
        </a>{' '}
        .
      </p>
      <p>
        Welche Daten grundsätzlich von Google erfasst werden und wofür diese
        Daten verwendet werden, können Sie auch auf{' '}
        <a href="https://policies.google.com/privacy?hl=de&amp;tid=112735006">
          https://www.google.com/intl/de/policies/privacy/
        </a>{' '}
        nachlesen.
      </p>
      <h2 id="schlusswort">Schlusswort</h2>
      <p>
        Herzlichen Glückwunsch! Wenn Sie diese Zeilen lesen, haben Sie sich
        wirklich durch unsere gesamte Datenschutzerklärung „gekämpft“ oder
        zumindest bis hier hin gescrollt. Wie Sie am Umfang unserer
        Datenschutzerklärung sehen, nehmen wir den Schutz Ihrer persönlichen
        Daten, alles andere als auf die leichte Schulter.
        <br />
        Uns ist es wichtig, Sie nach bestem Wissen und Gewissen über die
        Verarbeitung personenbezogener Daten zu informieren. Dabei wollen wir
        Ihnen aber nicht nur mitteilen, welche Daten verarbeitet werden, sondern
        auch die Beweggründe für die Verwendung diverser Softwareprogramme
        näherbringen. In der Regel klingen Datenschutzerklärung sehr technisch
        und juristisch. Da die meisten von Ihnen aber keine Webentwickler oder
        Juristen sind, wollten wir auch sprachlich einen anderen Weg gehen und
        den Sachverhalt in einfacher und klarer Sprache erklären. Immer ist dies
        natürlich aufgrund der Thematik nicht möglich. Daher werden die
        wichtigsten Begriffe am Ende der Datenschutzerklärung näher erläutert.
        <br />
        Bei Fragen zum Thema Datenschutz auf unserer Website zögern Sie bitte
        nicht, uns oder die verantwortliche Stelle zu kontaktieren. Wir wünschen
        Ihnen noch eine schöne Zeit und hoffen, Sie auf unserer Website bald
        wieder begrüßen zu dürfen.
      </p>
      <p>Alle Texte sind urheberrechtlich geschützt.</p>
      <p style={{ marginTop: '15px' }}>
        Quelle: Erstellt mit dem{' '}
        <a
          href="https://www.adsimple.at/datenschutz-generator/"
          title="Datenschutz Generator Österreich von AdSimple"
        >
          Datenschutz Generator Österreich
        </a>{' '}
        von AdSimple
      </p>
    </div>
  );
};

export default Dsgvo;
