import React, { Component } from 'react';
import './App.css';
import { Header } from '../layouts/index';
import CssBaseline from '@material-ui/core/CssBaseline';
import WhatsAppButton from '../whatsAppButton';
import CookieSnackbar from '../content/cookieSnackbar';

class App extends Component {

  state = {
    snackbar: localStorage.getItem('leitold.cookiesaccepted') !== 'true',
  }

  setCookiesAccepted = () => {
    this.setState({
      snackbar: false
    });
    localStorage.setItem('leitold.cookiesaccepted', 'true');
  }

  render() {
    return (
      <div>
        <CssBaseline />
        <Header />
        <WhatsAppButton />
        <CookieSnackbar 
          open={this.state.snackbar} 
          callback={this.setCookiesAccepted.bind(this)}
        />
      </div>
    );
  }
}

export default App;
