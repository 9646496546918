import React from 'react';
import { 
  withStyles,
  Grid,
  Button,
  Slide,
  Paper,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = theme => ({
  snackbar: {

    [theme.breakpoints.down('xs')]: {
      left:'0px',
      right:'0px',
      height:'250px'  
    },
    [theme.breakpoints.up('sm')]: {
      left:'50px',
      right:'50px',
      height: '180px',
    },
    position: 'fixed',
    bottom: '0px',
    
    
    
  },
  cookiePaper: {
    [theme.breakpoints.down('xs')]: {
      height:'250px'  
    },
    [theme.breakpoints.up('sm')]: {
      height: '180px',
    },
    width: '500px',
    padding: '20px',
    opacity: '0.9',
  },
  
});



class CookieSnackbar extends React.Component {

  handleClose = () => {
    this.props.callback();
  };

  render() {

    const {classes} = this.props;
    return (
      <Slide in={this.props.open} direction='up'>
        <div className={classes.snackbar}>
          <Grid container justify='center'>
            <Paper elevation={5} className={classes.cookiePaper}>
              <Grid container >
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>
                    Informationen zum Datenschutz
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>
                    Diese Seite verwendet Cookies. 
                    Sie stimmen der Verwendung von Cookies durch Anklicken von "OK" zu. 
                    Nähere Informationen finden Sie in unseren <Link to='/datenschutz'>Datenschutzbestimmungen</Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.okButton}>
                  <Grid container justify='center'>
                    <Button variant='contained' size='small' color='secondary' onClick={this.handleClose}>
                      OK
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      </Slide>
    );
  }

}

export default withStyles(styles, {withTheme:true})(CookieSnackbar);