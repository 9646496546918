import React from 'react';
import { 
  withStyles,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grow,
  List, 
  ListItem,
  ListItemText,
  ListItemIcon,
 } from '@material-ui/core';
 import FolderIcon from '@material-ui/icons/ArrowRight';
 import referenceData from '../../data/references';

const styles = theme => ({
  card: {
    maxWidth: 345,
    minHeight: 300,
    marginBottom: '20px'
  },
  media: {
    height: 240,
    objectFit: 'cover',
  },
  titleGrid: {
    marginTop:'30px',
  },
  cardGrid: {
    marginTop:'30px',
    marginBottom:'30px',
  },
  cardLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  }
});

class References extends React.Component {

  renderServices = (service, index) => {
    
    return(
      <Typography component="p" key={index} >
        - {service.description}            
      </Typography>
    );
    
  }

  renderList = (item, index) => {
  
    return (
      <ListItem key={index}>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText
          primary={item.name}
        />
      </ListItem>
    );
  }

  renderCards = (item, index) => {

    const {classes} = this.props;

    return(
        <Grow in timeout={1000} key={index}>
          <Grid item xs={12} sm={6} lg={4}>
            <Grid container justify='center'>
              <Card className={classes.card}>
                <a target="_blank" rel="noopener noreferrer" href={item.link} className={classes.cardLink}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="img"
                      className={classes.media}
                      height="140"
                      image={item.image}
                      title=""
                    />
                    <i>{item.copyright}</i>
                    <CardContent>
                      <Typography gutterBottom variant="subtitle1" component="h2">  
                        {item.name}
                      </Typography>
                      {item.services.map(this.renderServices)}
                    </CardContent>
                  </CardActionArea>
                </a>
              </Card>
              
            </Grid>
          </Grid>
        </Grow> 
    );
  }

  render(){

    const {classes} = this.props;

    return(
      <div>
        <Grid container >
          {
            // Title Grid
          }
          <Grid item xs={12} className={classes.titleGrid}>
            <Grid container justify='center'>
              <Typography variant='h2' color='textPrimary'>
                Referenzen
              </Typography>
            </Grid>
            <Grid container justify='center'>
              <Typography variant='subtitle1' color='textPrimary' align='center'>
                ... hier finden Sie eine Auswahl unserer Projekte!
              </Typography>
            </Grid>
          </Grid>
          {
            // Card Grid
          }
          <Grid item xs={12} className={classes.cardGrid}>
            <Grid container spacing={0}>
              {referenceData.references.map(this.renderCards)}
            </Grid>
          </Grid>
          {
            // List Grid
          }
          <Grid item xs={12}>
            <Grid container justify='center'>
              <Typography variant='subtitle1' color='textPrimary' align='center' gutterBottom>
                Auch mit diesen Kunden haben wir bereits Projekte durchgeführt:
              </Typography>
            </Grid>
            <Grid container justify='center'>
              <List dense={false}>
                {referenceData.referenceListSmall.map(this.renderList)}  
              </List>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

}

export default withStyles(styles, {withTheme:true})(References);