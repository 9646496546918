import React from 'react';
import {
  withStyles,
  Grid
} from '@material-ui/core';
import PDF from '../../assets/pdf/zuhauseWohlfuehlen042019.pdf';

const styles = theme => ({
  pdf: {
    width: '100%',
    minHeight: '200vh'
  }
})

const CurrentPromotionPage = (props) =>{

    const {classes} = props;
    
    return (
      <div>
        <Grid container justify='center'>
          
        <object data={PDF} type="application/pdf" className={classes.pdf}>
            <p>Ihr Brwoser hat kein PDF Plugin.
            Sie können <a href={PDF}>hier klicken um das PDF herunterzuladen</a></p>
        </object>

        </Grid>
      </div>
    );
  
}

export default withStyles(styles, { withTheme:true })(CurrentPromotionPage);

