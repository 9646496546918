import React from 'react';
import {
  withStyles,
  Grid
} from '@material-ui/core';
import PDF from '../../assets/AGBs.pdf';

const styles = theme => ({
  pdf: {
    width: '100%',
    height: '100vh'
  }
})

class AgbPage extends React.Component {

  render() {
    const {classes} = this.props;
    return (
      <div>
        <Grid container justify='center'>
          {
            /*
            <embed 
            src={PDF}
            className={classes.pdf}
            alt="pdf" 
            pluginspage="http://www.adobe.com/products/acrobat/readstep2.html" 
          />
            */
          }
          
          <object data={PDF} type="application/pdf" className={classes.pdf}>
            <p>Your web browser doesn't have a PDF plugin.
            Instead you can <a href={PDF}>click here to
            download the PDF file.</a></p>
          </object>
        </Grid>
      </div>
    );
  }

}

export default withStyles(styles, { withTheme:true })(AgbPage);

