import React from 'react';
import serviceData from '../../../data/services';
import { Spa1, Spa3 } from '../../../assets/index';
import ContentPage from './contentPage';



class Wellness extends React.Component {

  render() {
    return (
      <ContentPage
        headline={serviceData.services[1].name}
        infoText={serviceData.services[1].text}
        pic1={Spa1}
        pic2={Spa3}
      />
    );
  }
}

export default Wellness;