import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Fade, Grid } from '@material-ui/core';
import { Parallax } from 'react-parallax';

const styles = (theme) => ({
  container: {
    width: '100%',
    height: 'auto',
    backgroundSize: 'cover',
  },
  headline: {
    position: 'relative',
    top: '200px',
  },
  debug: {
    border: '2px solid red',
  },
  paraDiv: {
    height: '500px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileDiv: {
    height: '500px',
    textAlign: 'center',
    backgroundImage: 'radial-gradient(red, orange)',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  infoDiv: {
    height: '500px',
    backgroundImage: 'radial-gradient(white, lightgrey)',
    // eslint-disable-next-line
    display: '-webkit-box',
    // eslint-disable-next-line
    display: '-ms-flexbox',
    // eslint-disable-next-line
    display: 'flex',
    '-webkit-box-align': 'center',
    '-ms-flex-align': 'center',
    'align-items': 'center',
    '-webkit-box-pack': 'center',
    '-ms-flex-pack': 'center',
    'justify-content': 'center',
    padding: '10px',
    //width: '100%'
  },
  icon: {
    fontSize: '40px',
    marginTop: '20px',
    width: '100%',
    color: theme.palette.primary.main,
  },
  paperText: {
    transitionDuration: '1s',
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        '-webkit-transform': 'scale(1.1)',
        '-ms-transform': 'scale(1.1)',
        transform: 'scale(1.1)',
        color: 'black',
        transitionDuration: '1s',
      },
    },
  },
  grower: {
    padding: '10px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
});

class ContentPage extends React.Component {
  renderPuw = () => {
    if (this.props.headline === 'Alternativenergien') {
      return (
        <a
          href="http://photovoltaik-waermepumpen.at"
          className={this.props.classes.paperText}
        >
          <img src={this.props.logoPUW} alt="logoPUW" width="200px" />
        </a>
      );
    }
  };

  renderBathLink = () => {
    if (
      this.props.headline === 'Barrierefreies Bad' ||
      this.props.headline === 'Wellnessoase'
    ) {
      return (
        <a
          href="https://meisterbad.lsi.at/ad/"
          className={this.props.classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            align="center"
            variant="subtitle1"
            color="textPrimary"
            className={this.props.classes.paperText}
          >
            <strong>Nähere Infos finden Sie hier!</strong>
          </Typography>
        </a>
      );
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Fade in={true} timeout={2000}>
        <div className={classes.container}>
          <Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={this.props.pic1}
            bgImageAlt="pic1"
            strength={500}
          >
            <div className={classes.paraDiv}>
              <Typography
                align="center"
                variant="h3"
                color="textSecondary"
                className={classes.headline}
              >
                {this.props.headline}
              </Typography>
            </div>
          </Parallax>
          <div
            className={classes.mobileDiv}
            style={{
              backgroundImage: 'url(' + this.props.pic1 + ')',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Typography
              align="center"
              variant="h3"
              color="textSecondary"
              className={classes.headline}
            >
              {this.props.headline}
            </Typography>
          </div>
          <div className={classes.infoDiv}>
            <Grid container spacing={4} justify="center">
              <Grid item xs={12} md={8}>
                <Grid container justify="center">
                  <Typography
                    align="center"
                    variant="subtitle1"
                    color="textPrimary"
                    className={classes.paperText}
                  >
                    {this.props.infoText}
                  </Typography>
                </Grid>
                <Grid container justify="center">
                  {this.renderPuw()}
                  {this.renderBathLink()}
                  {this.props.children}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div
            className={classes.mobileDiv}
            style={{
              backgroundImage: 'url(' + this.props.pic2 + ')',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
          <Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={this.props.pic2}
            bgImageAlt="pic2"
            strength={500}
          >
            <div className={classes.paraDiv}>
              <Typography
                align="center"
                variant="h5"
                color="textSecondary"
                className={classes.headline}
              ></Typography>
            </div>
          </Parallax>
        </div>
      </Fade>
    );
  }
}

ContentPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContentPage);
