const serviceData = {
  "services": [
    {
      "name": "Barrierefreies Bad",
      "route": "/bad",
      "text": "Wir erarbeiten individuelle Lösungen, die perfekt auf Ihre Bedürfnisse abgestimmt sind. Besondere bauliche Maßnahmen werden von uns mit viel Know-How umgesetzt. Ihr barrierefreies Traumbad statten wir mit hochwertigen Produkten aus. Während der Planung und Ausführung stehen wir Ihnen gerne mit Rat und Tat zur Seite."
    },
    {
      "name": "Wellnessoase",
      "route": "/wellness",
      "text": "Wasser, Wärme, Luft und Licht – wir erschaffen in Ihrem Bad eine Atmosphäre zum Wohlfühlen und Entspannen. Bei der Raumgestaltung vereinen wir Funktionalität und Design mit edlen Materialien wie Keramik. Waschtische, Toiletten und Bidets in zeitlos schönem Design und dezenter Farbgebung sind Blickfänge in jedem Badezimmer. Individuelle Akzente setzen wir mit Fliesen, Armaturen und Einrichtungen. Wir entwerfen Ihr Traumbad zur Belebung von Körper, Geist und Seele. Besuchen Sie uns hierzu gerne in unserem Schauraum."
    },
    {
      "name": "Klimaanlage",
      "route": "/klimaanlage",
      "text": "Klima ist für uns mehr als bloße Temperaturregelung. Richtige Klimatisierung ist neben der Jahreszeit auch von Feuchtigkeit, Geschwindigkeit und Qualität der Luft abhängig. Wir beachten diese Faktoren, wenn wir das perfekte Wohlfühlklima für Ihr Zuhause, Ihre EDV-Räume, Labors, Serverräume, Geschäftsräume, Arzt-Praxen, Technikräume etc. planen."
    },
    {
      "name": "Heizung",
      "route": "/heizung",
      "text": "Wärme und Behaglichkeit entstehen in Ihren vier Wänden durch das richtige Heizsystem. Bei der Wahl Ihrer individuellen Heizvariante stehen wir Ihnen gerne zur Seite und bieten Ihnen kompetente Beratung zu einer Vielzahl von Energielieferanten."
    },
    {
      "name": "Trinkwasser",
      "route": "/trinkwasser",
      "text": "Frisches und sauberes Trinkwasser ist das wichtigste Lebensmittel für uns Menschen. Mit unseren Systemlösungen im Bereich der Trinkwasserhygiene helfen wir Ihnen die Wasserqualität für Sie und Ihre Familie zu erhalten."
    },
    {
      "name": "Alternativenergien",
      "route": "/alternativenergien",
      "text": "Heizen im Einklang mit der Natur. Heizen Sie nachhaltig, klimafreundlich und effizient. Gerne bieten wir Ihnen unsere Erfahrung und innovativen Lösungen auf diesem Gebiet an. Nähere Informationen finden Sie hier: "
    }            
  ],
  "contact" : {
    "name" : "Kontakt",
    "route" : "/contact"
  }
}

export default serviceData;