import React from 'react';
import serviceData from '../../../data/services';
import ContentPage from './contentPage';
import { Heating1, Heating2 } from '../../../assets/index';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

class Heating extends React.Component {
  render() {
    return (
      <ContentPage
        headline={serviceData.services[3].name}
        infoText={serviceData.services[3].text}
        pic1={Heating1}
        pic2={Heating2}
      >
        <Link to="/heizungsrechner" style={{ marginTop: '10px' }}>
          <Button variant="contained" color="primary">
            Zum Heizungsrechner
          </Button>
        </Link>
      </ContentPage>
    );
  }
}

export default Heating;
