// This file is shared across the demos.

import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccessibleIcon from '@material-ui/icons/Accessible';
import SpaIcon from '@material-ui/icons/Spa';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import HeatIcon from '@material-ui/icons/Whatshot';
import WaterIcon from '@material-ui/icons/LocalDrink';
import BatteryIcon from '@material-ui/icons/BatteryChargingFull';



const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  } ,
  icon: {
    color: theme.palette.primary.main
  }
});

class SideMenuListItems extends React.Component {

  render() {
    return (
      <div>
        <Link to='/bad' className={this.props.classes.link} key='bath'>
          <ListItem button onClick={this.props.closeDrawer}>
            <ListItemIcon>
              <AccessibleIcon className={this.props.classes.icon}/>
            </ListItemIcon> 
            <ListItemText primary="Barrierefreies Bad" />
          </ListItem>
        </Link>
        <Link to='/wellness' className={this.props.classes.link} key="wellness">
          <ListItem button onClick={this.props.closeDrawer}>
            <ListItemIcon>
              <SpaIcon className={this.props.classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Wellnessoase" />
          </ListItem>
        </Link>
        <Link to='/klimaanlage' className={this.props.classes.link} key="ac">
          <ListItem button onClick={this.props.closeDrawer}>
            <ListItemIcon>
              <AcUnitIcon className={this.props.classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Klimaanlage" />
          </ListItem>
        </Link>
        <Link to='/heizung' className={this.props.classes.link} key="heating">
          <ListItem button onClick={this.props.closeDrawer}>
            <ListItemIcon>
              <HeatIcon className={this.props.classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Heizung" />
          </ListItem>
        </Link>
        <Link to='/trinkwasser' className={this.props.classes.link} key="water">
          <ListItem button onClick={this.props.closeDrawer}>
            <ListItemIcon>
              <WaterIcon className={this.props.classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Trinkwasser" />
          </ListItem>
        </Link>
        <Link to='/alternativenergien' className={this.props.classes.link} key="alternative">
          <ListItem button onClick={this.props.closeDrawer}>
            <ListItemIcon>
              <BatteryIcon className={this.props.classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Alternativenergien" />
          </ListItem>
        </Link>
      </div>
    );
  }
}

export default withStyles(styles)(SideMenuListItems);
  
