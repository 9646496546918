import React from 'react';
import serviceData from '../../../data/services';
import { Alt1, Alt2, LogoPUW } from '../../../assets/index';
import ContentPage from './contentPage';

class Alternative extends React.Component {
  
  render() {

    return (
      <ContentPage 
        headline={serviceData.services[5].name}
        infoText={serviceData.services[5].text}
        logoPUW={LogoPUW}
        pic1={Alt1}
        pic2={Alt2}
      />
    );
  }
}

export default Alternative;


