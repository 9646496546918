import React from 'react';
import { Grid, Typography } from '@material-ui/core';

 const PageNotFound = () => {
  return(
    <div style={{height:'100vh', margin: '40px'}}>
      <Grid container justify='center'>
        <Typography variant='h3' color='textPrimary'>
          Tut uns leid, diese Seite existiert leider nicht.
        </Typography>
      </Grid>
    </div>
  );
}

export default PageNotFound;