import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

const styles = {
  root: {
    padding: '40px',
    maxWidth: '1200px',
    minHeight: '100vh',
    margin: 'auto',
  },
};

class Impressum extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container justify="center">
          <Typography variant="h3" color="textPrimary" gutterBottom>
            Impressum
          </Typography>
          <Grid item xs={12}>
            <strong>LEITOLD GmbH</strong>
            <br />
            <strong>Gesellschaft mit beschränkter Haftung</strong>
            <br />
            <br />
            <strong>Gewerberechtlicher Geschäftsführer:</strong>
            <br />
            Ing. Peter Leitold
            <br />
            Kärntnerstraße 173
            <br />
            A-8700 Leoben
            <br />
            <br />
            <strong>Unternehmensgegenstand:</strong> Sanitär-, Heizungs- und
            Lüftungstechniker
            <br />
            <br />
            <strong>UID.Nr:</strong> ATU62186789
            <br />
            <br />
            <strong>Firmenbuchnummer:</strong> 272798z
            <br />
            <br />
            <strong>FB-Gericht:</strong> Leoben
            <br />
            <br />
            <strong>Sitz:</strong> Kärntnerstraße 173 A-8700 Leoben
            <br />
            <br />
            <strong>Tel.:</strong> +43 3842 / 42 4 32 – 0
            <br />
            <strong>Fax:</strong> +43 3842 / 42 4 32 – 9
            <br />
            <strong>e-mail:</strong> office@leitold.co.at
            <br />
            <strong>Internet:</strong> http://www.leitold.co.at
            <br />
            <br />
            <strong>Gestaltung und Erstellung:</strong>{' '}
            <a
              href="https://www.fago.solutions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              FaGo Solutions GmbH
            </a>{' '}
            &amp;{' '}
            <a
              href="https://anfragemagnet.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Anfragemagnet
            </a>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Impressum);
