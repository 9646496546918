import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Fade,
  Grid,
  Modal,
  Icon,
  Slide,
  withWidth,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { Logo, Landing, Gesund } from '../../assets/index';
import Configurator from '../configurator';
import generalData from '../../data/general';

const styles = (theme) => ({
  '@keyframes landingSwitchEffect': {
    '0%': {
      opacity: 1,
    },
    '45%': {
      opacity: 1,
    },
    '55%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },
  container: {
    width: '100%',
    height: 'calc(100vh - 65px)',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    // background: "radial-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.0)), url(" + Landing + ")",
    flexGrow: 1,
    position: 'relative',
  },
  landingImg: {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: 'calc(100vh - 65px)',
    position: 'absolute',
    left: 0,
    '-webkit-transition': 'opacity 1s ease-in-out',
    '-moz-transition': 'opacity 1s ease-in-out',
    '-o-transition': 'opacity 1s ease-in-out',
    transition: 'opacity 1s ease-in-out',
    zIndex: 0,
  },
  landingImgTop: {
    animationName: '$landingSwitchEffect',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    animationDuration: '9s',
    animationDirection: 'alternate',
  },
  logoContainer: {
    position: 'absolute',
    opacity: 0.9,
    bottom: '200px',
    left: '0px',
    padding: '20px',
    backgroundColor: '#ffffff',
    width: '200px',
    borderRadius: '0px 20px 20px 0px',
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
      width: '390px',
      bottom: '100px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '430px',
      bottom: '100px',
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  debug: {
    border: '1px solid red',
  },
  confButton: {
    marginTop: '20px',
    width: '190px',
    height: '100px',
  },
  modal: {
    width: '90vw',
    margin: 'auto',
    marginTop: '50px',
    //minHeight:'80vh',
    [theme.breakpoints.down('xs')]: {
      height: '80vh',
    },
    [theme.breakpoints.up('sm')]: {
      height: '90vh',
    },
    backgroundColor: 'white',
    position: 'relative',
    outline: 'none',
  },
  mailLink: {
    color: 'inherit',
    marginLeft: '7px',
  },
  contactGrid: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    marginTop: '50px',
  },
  contactIcon: {
    // marginBottom: '5px'
    fontSize: '15px',
  },
  mailIconContainer: {
    marginTop: '10px',
  },
  closeModalButton: {
    position: 'absolute',
    top: '-35px',
    right: '-35px',
    color: 'white',
  },
  currentPromotionBanner: {
    backgroundColor: '#ffffff',
    top: '40px',
    paddingBottom: '0px !important',
    [theme.breakpoints.up('md')]: {
      top: '60px',
    },
  },
  banner1: {
    top: '55px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      top: '75px',
    },
  },
  banner2: {
    top: '110px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      top: '130px',
    },
  },
  banner3: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    top: '165px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      top: '185px',
    },
  },
  banner: {
    position: 'absolute',
    opacity: 0.9,
    right: '0px',
    padding: '10px',
    borderRadius: '10px 0px 0px 10px',
    zIndex: 100,
    minWidth: '150px',
    maxWidth: '300px',
    textAlign: 'center',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    [theme.breakpoints.up('md')]: {
      minWidth: '250px',
    },
  },
  currentPromotionImg: {
    height: '40px',
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confModal: false,
      mailChimpModalOpen: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      let sub = localStorage.getItem('leitold.subscribed');
      if (sub !== 'true') {
        this.setState({ ...this.state, mailChimpModalOpen: true });
      }
    }, 1000);
  }

  handleMailChimpModalClose = () => {
    this.setState({ ...this.state, mailChimpModalOpen: false });
  };

  handleConfModalOpen = () => {
    this.setState({ ...this.state, confModal: true });
  };

  handleConfModalClose = () => {
    this.setState({ confModal: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <img alt="landing" className={classes.landingImg} src={Landing} />
        {/*
          for alternating landing image: 
          <img alt="landing top" className={`${classes.landingImg} ${classes.landingImgTop}`} src={Landing2} />
        */}
        {
          // configurator banner
        }
        <Slide in direction="left" timeout={1000}>
          <a
            className={`${classes.banner} ${classes.banner1}`}
            href="/heizungsrechner"
          >
            Heizungsrechner
          </a>
        </Slide>
        {
          // heating swap banner
        }
        <Slide in direction="left" timeout={1000}>
          <a
            className={`${classes.banner} ${classes.banner2}`}
            href="/rausausoel"
          >
            Förderung zum Heizungstausch
          </a>
        </Slide>
        {
          // contact banner
        }
        <Slide in direction="left" timeout={1000}>
          <div className={`${classes.banner} ${classes.banner3}`}>
            <div>
              <Icon fontSize="default" className={classes.contactIcon}>
                mail
              </Icon>
              <a
                href={`mailto:${generalData.impressum.mail}`}
                className={classes.mailLink}
              >
                {generalData.impressum.mail}
              </a>
            </div>
            <div>
              <Icon fontSize="default" className={classes.contactIcon}>
                phone
              </Icon>
              <a
                href={`tel:${generalData.impressum.phone}`}
                className={classes.mailLink}
              >
                {generalData.impressum.phone}
              </a>
            </div>
          </div>
        </Slide>
        {
          // logo banner
        }
        <Grid container justify="center">
          <Grid item xs={12}>
            <Fade in={true} timeout={3000}>
              <Grid container justify="center">
                <div className={classes.logoContainer}>
                  <img src={Logo} alt="Logo" className={classes.logo} />
                </div>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.confModal}
          onClose={this.handleConfModalClose}
        >
          <div className={classes.modal}>
            <IconButton
              onClick={this.handleConfModalClose}
              className={classes.closeModalButton}
            >
              <CloseIcon />
            </IconButton>
            <Configurator closeModal={this.handleConfModalClose} />
          </div>
        </Modal>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withWidth()(Home));
