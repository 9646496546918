import React from 'react';
import serviceData from '../../../data/services';
import ContentPage from './contentPage';
import { Ac1, Ac2 } from '../../../assets/index';


class AirCon extends React.Component {
  

  render() {

    return (
      <ContentPage
        headline={serviceData.services[2].name}
        infoText={serviceData.services[2].text}
        pic1={Ac1}
        pic2={Ac2}
      />
    );
  }
}

export default AirCon;

