import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { 
  withStyles, 
  Grid,
  Typography,
  Avatar,
  Slide,
  Grow,
 } from '@material-ui/core';
import { Ego, B1, B2, B3 } from '../../assets/index';
import generalData from '../../data/general';
import IntersectionVisible from 'react-intersection-visible';
import ContactForm from './contactForm';
import ImageCarousel from './imageCarousel';



const styles = theme => ({
  bigAvatar: {
    width: 300,
    height: 300,
    marginTop: '20px',
  },
  smallAvatar: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '30px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '30px',
    },
    marginBottom: '10px',
    width: 250,
    height: 250,
    
  },
  debug: {
    border: '2px solid red',
    
  },
  ceoContainer: {
    backgroundImage: "radial-gradient(white, lightgrey)",
    padding: '20px',
    height: '500px'
  },
  teamContainer: {
    //backgroundColor: theme.palette.primary.main,
    backgroundImage: "radial-gradient(lightgrey, white)",
    paddingTop: '50px',
    paddingBottom: '50px',
    minHeight: '500px'
  },
  locationContainer :{
    backgroundImage: "radial-gradient(lightgrey, white)",
    padding: '50px',
    minHeight: '400px'
  },
  formContainer :{
    //backgroundImage: "radial-gradient(white, lightgrey)",
    backgroundColor:'lightgrey',
    paddingTop: '50px',
    paddingBottom: '50px',
    minHeight: '400px',
    width: '100%'
  },
  mailLink: {
    color:'inherit'
  },
  hoverGrow: {
    transitionDuration:'1s',
    '&:hover': {
      '-webkit-transform': 'scale(1.1)',
      '-ms-transform': 'scale(1.1)',
      transform: 'scale(1.1)',
      transitionDuration: '1s',
    },
  },
  dataField: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '30px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '50px',
    },
  },
  impressum: {
    marginTop: '20px',
    
  },
  map: {
    marginTop:'20px'
  },
  teamCard: {
    [theme.breakpoints.down('xs')]: {
      width: '300px',
      height: '200px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '500px',
      height: '300px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '600px',
      height: '400px',
    },
    
    marginTop: '50px',
    marginBottom: '30px',
    //backgroundImage: "url("+ Team + ")",
    //backgroundSize: 'cover',
    //backgroundPosition: 'center',

  }

})


class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visibleCeo: false,
      visibleTeam: false,
      visibleLocation: false
    }
  }

  handleSectionVisible = (section) => {
    this.setState({
      [`visible${section}`]: true
    });
  }

  handleSectionHidden = (section) => {
    this.setState({
      [`visible${section}`]: false
    });
  }

  render() {
    
    const { classes } = this.props;

    return (

      <div>
        {
          // CEO
        }
        <IntersectionVisible
          onShow={() => this.handleSectionVisible("Ceo")}
        >
          <Grid container justify='center' className={classes.ceoContainer}>
                <Grow direction="down" timeout={1000} in={this.state.visibleCeo} mountOnEnter unmountOnExit>
                  <div>
                    <Avatar
                      alt="Avatar"
                      src={Ego}
                      className={classNames(classes.bigAvatar, classes.hoverGrow)}
                    />
                  </div>
                </Grow>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grow direction='up' timeout={1000} in={this.state.visibleCeo} mountOnEnter unmountOnExit>
                      <Typography variant="subtitle1" align='center'>
                        Ing. Peter Leitold & Diana Leitold
                        <br />
                        Geschäftsführung
                      </Typography>
                    </Grow>
                  </Grid>
                </Grid>
          </Grid>
        </IntersectionVisible>
          
        

        {
          // TEAM
        }
        <IntersectionVisible
          onShow={() => this.handleSectionVisible("Team")}
          //onHide={() => this.handleSectionHidden("Team")}
        >
          <Grid container justify='center' className={classes.teamContainer}>
            <Grid item xs={12}>
              <Grid container justify='center'>
                <Typography variant='h5' color='textPrimary'>
                  Das Team
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} >
              <Grid container justify='center'>
                <Grow direction="right" timeout={1000} in={this.state.visibleTeam} mountOnEnter unmountOnExit>
                  <div>
                    <Avatar
                      alt="Avatar"
                      src={B1}
                      className={classNames(classes.smallAvatar, classes.hoverGrow)}
                    />
                  </div>
                </Grow>
              </Grid>
              <Grid container justify='center' >
                <Grow direction="right" timeout={1000} in={this.state.visibleTeam} mountOnEnter unmountOnExit>
                  <Typography variant='subtitle1' align='center'>
                    Petra Valentek
                    <br />
                    Sekretariat
                  </Typography>
                </Grow>
              </Grid> 
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container justify='center'>
                <Grow direction="Bottom" timeout={1000} in={this.state.visibleTeam} mountOnEnter unmountOnExit>
                  <div>
                    <Avatar
                      alt="Avatar"
                      src={B2}
                      className={classNames(classes.smallAvatar, classes.hoverGrow)}
                    />
                  </div> 
                </Grow>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grow direction="left" timeout={1000} in={this.state.visibleTeam} mountOnEnter unmountOnExit>
                      <Typography variant='subtitle1' align='center'>
                        Chiara Dietrich
                        <br />
                        Sekretariat
                      </Typography>
                    </Grow>
                  </Grid>
                </Grid> 
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} >
              <Grid container justify='center'>
                <Grow direction="right" timeout={1000} in={this.state.visibleTeam} mountOnEnter unmountOnExit>
                  <div>
                    <Avatar
                      alt="Avatar"
                      src={B3}
                      className={classNames(classes.smallAvatar, classes.hoverGrow)}
                    />
                  </div>
                </Grow>
              </Grid>
              <Grid container justify='center' >
                <Grow direction="right" timeout={1000} in={this.state.visibleTeam} mountOnEnter unmountOnExit>
                  <Typography variant='subtitle1' align='center'>
                    Markus Stücklschweiger
                    <br />
                    Verkauf
                  </Typography>
                </Grow>
              </Grid> 
            </Grid>

            <Grid item xs={12} >
              <Grid container justify='center'>
                <Grow direction="right" timeout={1000} in={this.state.visibleTeam} mountOnEnter unmountOnExit>
                  <div>
                    {//<Card elevation={4} className={classNames(classes.teamCard,classes.hoverGrow)}/>
                    }
                    <ImageCarousel />
                    {/*<Card elevation={4} className={classes.teamCard}>
                      
                  </Card>*/}
                  </div>
                </Grow>
              </Grid>
              <Grid container justify='center' >
                <Grow direction="right" timeout={1000} in={this.state.visibleTeam} mountOnEnter unmountOnExit>
                  <Typography variant='subtitle1' align='center'>
                    Installateur-Profis
                  </Typography>
                </Grow>
              </Grid> 
            </Grid>
          </Grid>
        </IntersectionVisible>

        {
          // Form
        }
        <Grid container justify='center' className={classes.formContainer}>
          <ContactForm />
        </Grid>



        {
          // Location
        }
        <IntersectionVisible
          onShow={() => this.handleSectionVisible("Location")}
          //onHide={() => this.handleSectionHidden("Location")}
        >
          <Grid container justify='center' className={classes.locationContainer}>
            <Grid item xs={12}>
              <Grid container justify='center'>              
                <Typography variant='h5' color='textPrimary' align='center'>
                  Wo Sie uns finden
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.impressum}>
              <Grid item xs={12} sm={6}>
                
                  <Grid item xs={12} className={classes.dataField} >
                    <Grid container justify='center'>
                      <Slide direction="right" timeout={1000} in={this.state.visibleLocation} mountOnEnter unmountOnExit>
                        <Typography variant='subtitle1'>
                          {generalData.impressum.companyName}
                          <br/>
                            {generalData.impressum.address}
                          <br/>
                            {generalData.impressum.zip}
                          <br/>
                          Tel: &nbsp;
                          <a href={`tel:${generalData.impressum.phone}`}
                            className={classes.mailLink}>
                          {generalData.impressum.phone} 
                          </a>
                          <br/>
                          Email: &nbsp;
                          <a href={`mailto:${generalData.impressum.mail}?Subject=Anfrage`}
                            className={classes.mailLink}>
                            {generalData.impressum.mail}
                          </a>
                        </Typography>
                      </Slide>
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
        </IntersectionVisible>
      </div>

    
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme:true })(Contact);

