import { Slide } from '@material-ui/core';
import React from 'react';

import { WaLogo } from '../assets';

const buttonStyles = {
  position:'fixed',
  right:'40px',
  bottom:'40px', 
  width: '70px',
  height: '70px',
}

const WhatsAppButton = () => {
  return (
    <Slide in direction="up" timeout={1000}>
      <a target="_blank" rel="noopener noreferrer" href="https://wa.link/kuughf">
        <img
          style={buttonStyles}
          alt="WhatsappLogo"
          src={WaLogo}

        />    
      </a>
    </Slide>
  )
};

export default WhatsAppButton;