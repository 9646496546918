import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';

import "@fontsource/roboto";

const theme = createMuiTheme({
  palette: {
    primary: {
      //main: '#4A525A',
      main: '#818D92',
      light: '#818D92'
    },
    secondary: amber,
    type: 'light',
    text: {
      primary: '#585454',
      secondary: '#fafafa'
    },
  },
  typography: {
    headline: {
      fontSize: "1.3rem"
    }
  }
  
  
})


ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </MuiThemeProvider>
  , document.getElementById('root'));
