import React from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Fade,
} from '@material-ui/core';


const styles = theme => ({
  container: {
    minHeight: '100vh',
  },
  titleGrid: {
    marginTop: '50px',
  },
  videoWrapper: {
    margin: 'auto',
    position: 'relative',
    paddingBottom: '56.25%', /* 16:9 */
    height: 0,
    marginLeft: 20,
    marginRight: 20,
  },
  videoElement: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }
});

const EnvironmentalPromotion = ({ classes }) => {
  return (
    <Fade in timeout={1000}>
      <Grid container className={classes.container}>
        <Grid container justify='center'>
          <Grid item xs={11} md={7} className={classes.titleGrid}>
            <Typography variant='h2' color='textPrimary' align='center'>
              Raus aus Öl
            </Typography>
          </Grid>
          <Grid item xs={11} md={7}>
            <Typography variant='subtitle1' color='textPrimary' align='center'>
              Steigen Sie jetzt auf eine klimafreundliche Heizform um (Holzzentralheizung, Wärmepumpe oder einen Nah-/Fernwärmeanschluss) und verabschieden Sie sich von der fossilen Vergangenheit (Öl, Gas, Kohle, Strom-Direktheizung, Allesbrenner).
              Bund und Länder bieten dazu attraktive Förderungen, welche Ihnen bis zu €11.000 bringen können!
              Weitere Informationen finden Sie <a target="_blank" rel="noopener noreferrer" href="https://www.umweltfoerderung.at/privatpersonen/raus-aus-oel.html">hier</a>.
              <br />
              Ihr Beitrag für die Umwelt – umgesetzt von Leitold GmbH.
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify='center'>
          <Grid item xs={12} md={8} lg={7}>
            <div className={classes.videoWrapper}>
              <iframe
                title="Raus aus Öl"
                className={classes.videoElement}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/UqEkdNKvYKI"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default withStyles(styles, { theme: true })(EnvironmentalPromotion);

