import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import serviceData from '../../data/services';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Logo } from '../../assets/index';
import SideMenuListItems from './menuItems';
import Home from '../content/home';
import News from '../content/news';
import Footer from './footer';
import AgbPage from '../content/agb';
import Dsgvo from '../content/dsgvo';
import PageNotFound from './pageNotFound';
import References from '../content/references';
import Impressum from '../content/impressum';
import CurrentPromotionPage from '../content/currentPromotion';
import {
  AppBar,
  Button,
  Toolbar,
  Drawer,
  Divider,
  List,
  IconButton,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import {
  AccessibleBath,
  AirCon,
  Alternative,
  Heating,
  Water,
  Wellness,
} from '../content/services/index';
import Contact from '../content/contact';
import EnvironmentalPromotion from '../content/environmentalPromotion';
import Configurator from '../configurator';

const drawerWidth = 260;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appFrame: {
    zIndex: 0,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },
  appBar: {
    position: 'absolute',
    minHeight: '65px',
    backgroundColor: theme.palette.primary.light,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    opacity: '1',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'appBarShift-left': {
    marginLeft: drawerWidth,
  },
  'appBarShift-right': {
    marginRight: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    //marginLeft: -drawerWidth,
  },
  'content-right': {
    //marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
  logo: {
    width: '150px',
    //padding: '10px',
  },
  headerLogoContainer: {
    //width: '150px',
    top: '10px',
    right: '10px',
    position: 'absolute',
  },
  link: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    textDecoration: 'none',
    color: 'white',
  },
  toolBarButton: {
    padding: '20px',
  },
  redBorder: {
    border: '1px solid red',
  },
  serviceItem: {
    color: theme.palette.primary.main,
    fontSize: '15px',
  },
  linkNoDecoration: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
});

class Header extends Component {
  state = {
    open: false,
    anchor: 'left',
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  renderButton = (service) => {
    return (
      <Link
        key={service.name}
        to={service.route}
        className={this.props.classes.link}
      >
        <Button color="inherit" className={this.props.classes.toolBarButton}>
          {service.name}
        </Button>
      </Link>
    );
  };

  render() {
    const { classes, theme } = this.props;
    const { open, anchor } = this.state;

    const drawer = (
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        ModalProps={{ onBackdropClick: this.handleDrawerClose }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <ListSubheader className={classes.serviceItem}>
          Unsere Services
        </ListSubheader>
        <List>
          <SideMenuListItems closeDrawer={this.handleDrawerClose} />
        </List>
        <Divider />
        <List>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.haustechnikmitzukunft.at"
            className={classes.linkNoDecoration}
          >
            <ListItem button onClick={this.handleDrawerClose}>
              <ListItemIcon>
                <ShoppingCartIcon className={this.props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Shop" />
            </ListItem>
          </a>
          <Link
            to="/rausausoel"
            className={classes.linkNoDecoration}
            key="rausausoel"
          >
            <ListItem button onClick={this.handleDrawerClose}>
              <ListItemText primary="Raus aus Öl" />
            </ListItem>
          </Link>
          <Link
            to="/heizungsrechner"
            className={classes.linkNoDecoration}
            key="configurator"
          >
            <ListItem button onClick={this.handleDrawerClose}>
              <ListItemText primary="Heizungsrechner" />
            </ListItem>
          </Link>
          <Link to="/news" className={classes.linkNoDecoration} key="news">
            <ListItem button onClick={this.handleDrawerClose}>
              <ListItemText primary="News" />
            </ListItem>
          </Link>
          <Link
            to="/contact"
            className={classes.linkNoDecoration}
            key="contact"
          >
            <ListItem button onClick={this.handleDrawerClose}>
              <ListItemText primary="Kontakt" />
            </ListItem>
          </Link>
          <Link
            to="/referenzen"
            className={classes.linkNoDecoration}
            key="references"
          >
            <ListItem button onClick={this.handleDrawerClose}>
              <ListItemText primary="Referenzen" />
            </ListItem>
          </Link>
          <Link
            to="/impressum"
            className={classes.linkNoDecoration}
            key="impressum"
          >
            <ListItem button onClick={this.handleDrawerClose}>
              <ListItemText primary="Impressum" />
            </ListItem>
          </Link>
          <Link
            to="/datenschutz"
            className={classes.linkNoDecoration}
            key="dsgvo"
          >
            <ListItem button onClick={this.handleDrawerClose}>
              <ListItemText primary="Datenschutz" />
            </ListItem>
          </Link>
          <Link
            to="/zuhauseWohlfuehlen"
            className={classes.linkNoDecoration}
            key="zuhauseWohlfühlen"
          >
            <ListItem button onClick={this.handleDrawerClose}>
              <ListItemText primary="Zuhause wohlfühlen" />
            </ListItem>
          </Link>
        </List>
      </Drawer>
    );

    let before = null;
    let after = null;
    before = drawer;
    let list;
    list = serviceData.services.map(this.renderButton);
    list.push(this.renderButton(serviceData.contact));

    return (
      <div className={classes.appFrame}>
        {/*<AppBar
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
              [classes[`appBarShift-${anchor}`]]: open,
            })}
          >
          */}
        <AppBar className={classes.appBar}>
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              //className={classNames(classes.menuButton, open && classes.hide)}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            {list}

            {
              //this.renderButton(serviceData.contact)
            }
            <div className={classes.headerLogoContainer}>
              <Link to="/">
                <img src={Logo} alt="logo" className={classes.logo} />
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        {before}
        <main
          className={classNames(classes.content, classes[`content-${anchor}`], {
            [classes.contentShift]: open,
            [classes[`contentShift-${anchor}`]]: open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/bad" component={AccessibleBath} />
            <Route exact path="/wellness" component={Wellness} />
            <Route exact path="/klimaanlage" component={AirCon} />
            <Route exact path="/heizung" component={Heating} />
            <Route exact path="/trinkwasser" component={Water} />
            <Route exact path="/alternativenergien" component={Alternative} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/news" component={News} />
            <Route exact path="/agb" component={AgbPage} />
            <Route exact path="/datenschutz" component={Dsgvo} />
            <Route exact path="/referenzen" component={References} />
            <Route exact path="/impressum" component={Impressum} />
            <Route exact path="/heizungsrechner" component={Configurator} />
            <Route
              exact
              path="/zuhauseWohlfuehlen"
              component={CurrentPromotionPage}
            />
            <Route
              exact
              path="/rausausoel"
              component={EnvironmentalPromotion}
            />
            <Route component={PageNotFound} />
          </Switch>

          <Footer />
        </main>
        {after}
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Header);
