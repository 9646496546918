import React from 'react';
import serviceData from '../../../data/services';
import { Bath4, Bath2 } from '../../../assets/index';
import ContentPage from './contentPage';


class AccessibleBath extends React.Component {
  

  render() {
    return (
      <ContentPage
        headline={serviceData.services[0].name}
        infoText={serviceData.services[0].text}
        pic1={Bath4}
        pic2={Bath2}
      />
    );
  }
}


export default AccessibleBath;

