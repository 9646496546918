import React from 'react';
import { 
  withStyles, 
  Grid,
  Typography,
  TextField,
  Button,
  Snackbar,
  IconButton,
  Checkbox
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import ReCAPTCHA from "react-google-recaptcha";
import keys from '../../data/keys';
import axios from 'axios';


const styles = theme => ({
  container: {
    //display: 'flex',
    //flexWrap: 'wrap',
  },
  debug: {
    border: '2px solid red',
  },
  formPaper: {
    backgroundColor: 'lightgrey'
  },
  textFieldShort: {
    width: '200px',
  },
  textFieldLarge: {
    width: '200px',
  },
  sendButton: {
    marginTop:'20px',
    marginBottom:'20px',
  },
  errorMessage: {
    color: 'red'
  },
  successMessage: {
    color: 'green',
  },
  captcha: {
    marginTop: '20px'
  },
  gdprText: {
    marginTop:'14px'
  }
})

class ContactForm extends React.Component {

  state=({
    formName: '',
    formMail: '',
    formText: '',
    snackMessage: '',
    snackbarOpen: false,
    gdprChecked: false,
    captcha: 'NO'
  });

  handleChange = name => event => {
    var stripped = event.target.value.replace(/[^A-ZA-Z0-9@,.!?*# +=€$]+/i, '');
    this.setState({
      [name]: stripped,
    });
  };

  handleGdprChecked = event => {
    this.setState({
      gdprChecked: event.target.checked
    })
  }

  handleSend = () => {
    
    if(this.validate() === false){
      this.setState({snackbarOpen:true});
    }
    else {
      this.setState({ snackMessage: 'Gesendet. Wir melden uns in Kürze!' });

      var data = {
        name: this.state.formName,
        mail: this.state.formMail,
        text: this.state.formText
      }

      axios.post(keys.zapierApi, data, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });

      this.setState({
        snackbarOpen:true,
        formName: '',
        formMail: '',
        formText: '',
        captcha: 'NO'
      });
    }
    
  }

  validate = () => {

    // Validate Name
    if(this.state.formName.length < 3) {
      this.setState({ snackMessage: 'Name muss mindestens aus 3 Zeichen bestehen!' });
      return false;
    }
    if(this.state.formName.length > 30) {
      this.setState({ snackMessage: 'Name darf maximal 30 Zeichen betragen!' });
      return false;
    }

    // Validate Mail 
    if(this.state.formMail.length > 30) {
      this.setState({ snackMessage: 'Adresse darf maximal 30 Zeichen betragen!' });
      return false;
    }
    var re = /\S+@\S+\.\S+/;
    if(re.test(this.state.formMail) === false || this.state.formMail.length < 7) {
      this.setState({ snackMessage: 'Keine gültige Mailadresse' });
      return false;
    }

    // Validate Text
    if(this.state.formText.length < 1) {
      this.setState({ snackMessage: 'Bitte Anfrage ausfüllen' });
      return false;
    }

    if(this.state.formText.length > 500) {
      this.setState({ snackMessage: 'Maximal 500 Zeichen!' });
      return false;
    }

    // Validate Checkbox
    if(!this.state.gdprChecked) {
      this.setState({ snackMessage: 'Bitte Datenschutzbestimmungen bestätigen' });
      return false;
    }

    // Validate Captcha
    if(this.state.captcha === 'NO') {
      this.setState({ snackMessage: 'Bitte Captcha ausfüllen' });
      return false;
    }
    return true;
  }

  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false})
  }

  captchaChange = (value) => {
    this.setState({
      captcha: value
    })
  }

  render() {

    const {classes} = this.props;

    return(
      <div className={classes.container}>
        {/*<Grid container direction="row">*/}
          <Grid item xs={12}>
            <Typography variant='h5' color='textPrimary' align='center'>
              Kontaktieren Sie uns!            
            </Typography>
          </Grid>
          <Grid item xs={12}>
            
              <Grid container justify='center'>
                <form noValidate autoComplete="off" className={classes.form}>
                  <Grid item xs={12}>
                    <Grid container justify='center'>
                      <TextField
                        id="name"
                        label="Name"
                        className={classes.textFieldShort}
                        value={this.state.formName}
                        onChange={this.handleChange('formName')}
                        margin="normal"
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='center'>
                      <TextField
                        id="mail"
                        label="Mail"
                        color='secondary'
                        className={classes.textFieldShort}
                        value={this.state.formMail}
                        onChange={this.handleChange('formMail')}
                        margin="normal"
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='center'>
                      <TextField
                        id="text"
                        label="Anfrage"
                        multiline
                        rowsMax="5"
                        className={classes.textFieldLarge}
                        value={this.state.formText}
                        onChange={this.handleChange('formText')}
                        margin="normal"
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='center'>
                      <Typography variant='body2' className={classes.gdprText}>
                        <Link to='/datenschutz'>Datenschutzbestimmungen</Link> gelesen und akzeptiert:
                      </Typography>
                      <Checkbox
                        checked={this.state.gdprChecked}
                        onChange={this.handleGdprChecked}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='center'>
                      <ReCAPTCHA
                        className={classes.captcha}
                        sitekey={keys.captchaKey}
                        onChange={this.captchaChange}
                      />
                    </Grid>
                  </Grid>
                </form>
                <Grid item xs={12}>
                    <Grid container justify='center'>
                      <Button 
                        variant='contained' 
                        color='secondary' 
                        className={classes.sendButton}
                        onClick={this.handleSend}>
                        Senden
                      </Button>
                    </Grid>
                  </Grid>
              </Grid>
             
          </Grid>
        {/*</Grid>*/}

        <Snackbar
          open={this.state.snackbarOpen}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackbarClose}
            >
              <Close />
            </IconButton>,
          ]}
        />

      </div>
    );

  }

}


export default withStyles(styles, { withTheme:true })(ContactForm);