import React from 'react';
import FacebookProvider, { Page } from 'react-facebook';
import {
  Grid, 
  withStyles,
  Paper,
  Typography,
  Slide
} from '@material-ui/core';
import { SocialIcon } from 'react-social-icons';

const styles = theme => ({
  debug: {
    border : '1px solid red'
  },
  fbPage: {
    
    [theme.breakpoints.down('xs')]: {
      width: '300px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '500px',
      position: 'absolute',
      left:'50vw'
    },
  },
  headerGrid: {
    marginTop:'50px'
  },
  newsGrid: {
    marginTop:'50px',
    marginBottom:'50px'
  },
  textFb: {
    color: '#3b5998',
    marginTop: '30px'
  },
  textSocial: {
    marginTop: '30px'
  },
  container: {
    minHeight:'100vh',
    backgroundImage: "radial-gradient(white, lightgrey)"
  },
  headingPaper: {
    
    
    backgroundColor: 'white',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
      marginRight:'20px',
      marginLeft:'20px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
      marginRight:'50px',
      marginLeft:'50px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '20px',
      marginRight:'50px',
      marginLeft:'50px',
    },
  },
  social: {
    marginTop:'20px',
  }
});



class News extends React.Component {

  render(){

    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Grid container justify='center'>
          {
            // Heading Grid
          }
          <Grid item xs={12} md={6} className={classes.headerGrid}>
          <Slide in timeout={1000} direction='right'>
            <Paper elevation={5} className={classes.headingPaper}>
              <Grid container justify='center'>
                
                  <Typography variant='h5' color='textPrimary' align='center'>
                    Aktuelle Neuigkeiten
                  </Typography>
                
              </Grid>
              <Grid container justify='center'>
                <Grid item xs={9} className={classes.textFb}>
                    <Typography variant='h6' color='inherit' align='center'>
                      Folgen Sie uns doch auf Facebook!
                    </Typography>
                </Grid>
                <Grid item xs={9} className={classes.textSocial}>
                    <Typography variant='subtitle1' align='justify'>
                      Sie wollen alle News, Aktionen und die neuesten Produkte als Erste erfahren?
                      Dann folgen sie uns auf Facebook und Instagram!
                      Regelmäßig posten wir dort Neuigkeiten, sowie interessante Informationen rund um die Themen Bäder, Heizen und Co.
                      Hier geht es direkt zu unseren Seiten:
                    </Typography>
                  <Grid container justify='center' className={classes.social}>
                      <SocialIcon url='https://www.facebook.com/haustechnikmitzukunft/' />
                      <SocialIcon url='https://www.instagram.com/leitoldgmbh/' />                    
                      <SocialIcon url='https://www.linkedin.com/in/peter-leitold-489046121/' />
                  </Grid>   
                </Grid>
              </Grid>
            </Paper>
            </Slide>
          </Grid>
          
          
        
          {
            // News Grid
          }
          <Grid item xs={12} md={6} className={classes.newsGrid}>
              <Grid container justify='center'>
                <FacebookProvider appId="1052580031584102">
                  <Paper elevation={4}>
                    <Page 
                      href="https://www.facebook.com/haustechnikmitzukunft/" 
                      tabs="timeline" 
                      width="500px"
                      height="600px"
                      smallHeader
                      className={classes.fbPage}
                    />
                  </Paper>
                  
                </FacebookProvider>
              </Grid>
          </Grid>
        </Grid>
      </div>
      
    );
  }

}

export default withStyles(styles, { theme: true })(News);
