import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { withStyles } from '@material-ui/core';
import {
  Team1,
  Team2,
  Team3,
  Team4,
  Team5,
  Team6,
} from '../../assets/index';

const styles = (theme) => ({

  carusi: {
    [theme.breakpoints.down('xs')]: {
      width: '300px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '800px',
    },
    marginTop: '40px',
    marginBottom: '10px',
    borderRadius: '5px'
  }
})

const ImageCarousel = (props) => {

  const { classes } = props;

  return(
    <Carousel
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      emulateTouch={true}
      className={classes.carusi}
      dynamicHeight={true}
      showIndicators={false}
      //centerMode={true}
      //centerSlidePercentage={100}
      >
        <div>
            <img src={Team1} alt=""/>
        </div>
        <div>
            <img src={Team2} alt=""/>
        </div>
        <div>
            <img src={Team3} alt=""/>
        </div>
        <div>
            <img src={Team4} alt=""/>
        </div>
        <div>
            <img src={Team5} alt=""/>
        </div>
        <div>
            <img src={Team6} alt=""/>
        </div>
    </Carousel>
  );
}

export default withStyles(styles, {withTheme: true})(ImageCarousel);